<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>


            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>
            <v-toolbar-title class="title">Bank Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="Copy()">
                <v-icon>mdi-content-copy</v-icon>
            </v-btn>


        </v-app-bar>

        <div class="main">

            <v-snackbar class="my-15" v-model="snackbar" :timeout="2000" rounded="pill">
                Bank Details Copied
            </v-snackbar>

            <!-- <h2>Your Bank Details</h2> -->
            <v-form ref="form" v-model="valid" lazy-validation>

                <h5>Bank</h5>

                <v-select v-model="bank" :items="banks" label="Select Bank" prepend-icon="mdi-credit-card-fast"
                    :hide-details="bank=='Other'"></v-select>

                <!-- <v-radio-group v-model="bank" mandatory hide-details>
                    <v-radio label="Capitec" value="capitec"></v-radio>
                    <v-radio label="FNB" value="fnb"></v-radio>
                    <v-radio label="Tyme Bank" value="tyme_bank"></v-radio>
                    <v-radio label="Nedbank" value="nedbank"></v-radio>
                    <v-radio label="Standard Bank" value="standard_bank"></v-radio>
                    <v-radio label="Old Mutual" value="old_mutual"></v-radio>
                    <v-radio label="Other" value="other"></v-radio>
                </v-radio-group> -->

                <v-text-field v-model="otherBank" v-if="bank=='Other'" :rules="nameRules" label="Bank Name"
                    prepend-icon="mdi-credit-card-fast" required>
                </v-text-field>

                <h5>Account Number</h5>

                <v-text-field v-model="accountNumber" :rules="nameRules" label="Account Number"
                    prepend-icon="mdi-credit-card-fast" required>
                </v-text-field>

                <h5>Account Name</h5>

                <v-text-field v-model="accountName" :rules="nameRules" label="Account Name"
                    prepend-icon="mdi-credit-card-fast" required>
                </v-text-field>

                <h5>Branch Code</h5>

                <v-text-field v-model="branchCode" :rules="nameRules" label="Branch Code"
                    prepend-icon="mdi-credit-card-fast" required>
                </v-text-field>

                <v-text-field v-model="swiftCode" :rules="nameRules" label="Swift Code"
                    prepend-icon="mdi-credit-card-fast" required>
                </v-text-field>

                <v-btn class="button" block :loading="loader" @click="PatchBankDetails(), loader = true">
                    Update
                </v-btn>

            </v-form>
        </div>
    </v-container>
</template>

<script>
    // import {
    //     Auth
    // } from "aws-amplify";
    import {
        API
    } from "aws-amplify";

    export default {
        data: () => ({
            loader: false,
            valid: true,

            banks: ['Capitec', 'FNB', 'Nedbank', 'Tyme Bank', 'Standard Bank', 'Old Mutual', 'Other'],
            bank: 'null',
            otherBank: '',

            accountNumber: '',

            accountName: '',

            branchCode: '',

            swiftCode: '',

            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],

            bankDetails: '',

            snackbar: false,


        }),

        methods: {
            Copy() {

                let bankName;

                if (this.bank == 'Other') {
                    bankName = this.otherBank
                } else {
                    bankName = this.bank
                }

                this.snackbar = true;

                this.bankDetails = "Bank Details: " + bankName.toUpperCase() + " | Account Number: " + this.accountNumber +
                    " | Account Name: " + this.accountName + " | Branch Code: " + this.branchCode + " | Swift Code: " + this.swiftCode

                navigator.clipboard.writeText(this.bankDetails);
            },

            async GetBankDetails() {
                const apiName = "MoyaAPI";
                const apiPath = "/bankAccount";
                try {
                    const response = await API.get(apiName, apiPath);
                    console.log(response);

                    this.bank = response.response.Item.Bank_Name.S,
                    this.accountNumber = response.response.Item.Account_Number.S,
                    this.accountName = response.response.Item.Account_Name.S,
                    this.branchCode = response.response.Item.Branch_Code.S,
                    this.swiftCode = response.response.Item.Swift_Code.S

                } catch (error) {
                    console.log(error.response);
                }
            },

            async PatchBankDetails() {

                let bankName;

                if (this.bank == 'Other') {
                    bankName = this.otherBank
                } else {
                    bankName = this.bank
                }

                const apiName = "MoyaAPI";
                const apiPath = "/bankAccount";
                const apiRequest = {
                    body: {
                        bankName: bankName,
                        accountNumber: this.accountNumber,
                        accountName: this.accountName,
                        branchCode: this.branchCode,
                        swiftCode: this.swiftCode
                    },
                };
                try {
                    const response = await API.patch(apiName, apiPath, apiRequest);
                    console.log(response);
                    this.loader = false
                } catch (error) {
                    console.log(error.response);
                    this.loader = false
                }
            },
        },

        created() {
            this.GetBankDetails();
            document.title = 'Moya | Bank Details'
        }
    }
</script>

<style scoped>
    .container {
        padding: 70px 30px 60px !important;
    }

    h4 {
        margin-bottom: 10px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }
</style>