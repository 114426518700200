<template>
    <v-container fluid>
        <v-icon class="back" v-if="step1" @click="Welcome()">chevron_left</v-icon>
        <v-icon class="back" v-if="step2" @click="Name()">chevron_left</v-icon>
        <v-icon class="back" v-if="step3" @click="Industry()">chevron_left</v-icon>
        <v-icon class="back" v-if="step4" @click="Services()">chevron_left</v-icon>
        <v-icon class="back" :disabled="loader" v-if="step5" @click="Time()">chevron_left</v-icon>

        <div class="step">
            <v-icon>work</v-icon>
            <v-icon :disabled="!step1">noise_control_off</v-icon>
            <v-icon :disabled="!step2">noise_control_off</v-icon>
            <v-icon :disabled="!step3">noise_control_off</v-icon>
            <v-icon :disabled="!step4">noise_control_off</v-icon>
            <v-icon :disabled="!step5">noise_control_off</v-icon>
        </div>

        <v-form ref="form1" v-model="valid1" v-if="step1">
            <h1>What's the name of your business?</h1>
            <v-text-field v-model="businessName" :rules="nameRules" label="Business Name" required>
            </v-text-field>

            <v-btn block :disabled="!valid1" @click="Industry()">
                Next
            </v-btn>
        </v-form>

        <v-form ref="form2" v-model="valid2" v-if="step2">
            <h1>What industry are you in?</h1>

            <v-row dense>
                <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
                    <v-card @click="activate(card.id, card.title)" :class="{ active : active_el == card.id }"
                        class="service">

                        <v-icon class="service-icon" v-text="card.icon"></v-icon>
                        <p v-text="card.title" class="service-title"></p>

                    </v-card>
                </v-col>
            </v-row>

            <v-btn block :disabled="!industry" :loading="loader" @click="Services()">
                Next
            </v-btn>
        </v-form>

        <v-form ref="form3" v-model="valid3" v-if="step3">
            <h1>What specific services do you offer?</h1>


            <v-combobox v-model="service" :items="items" chips clearable label="Services" multiple
                hint="Press enter after each service" persistent-hint :rules="nameRules">
                <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">
                        <strong>{{ item }}</strong>
                    </v-chip>
                </template>
            </v-combobox>


            <v-btn block :disabled="service.length == 0" :loading="loader" @click="Time()">
                Next
            </v-btn>
        </v-form>

        <v-form ref="form4" v-model="valid4" v-if="step4">

            <h1>How much time do you spend on your business?</h1>

            <v-radio-group v-model="time" mandatory>
                <v-radio label="Main Hustle (Full Time)" value="main_hustle"></v-radio>
                <v-radio label="Side Hustle (Part Time)" value="side_hustle"></v-radio>
                <!-- <v-radio label="Side Hustle" value="side_hustle"></v-radio> -->
            </v-radio-group>

            <v-btn block :disabled="!valid4" :loading="loader" @click="Register()">
                Next
            </v-btn>
        </v-form>

        <v-form ref="form5" v-model="valid5" v-if="step5">

            <h1>What is your legal setup?</h1>

            <v-radio-group v-model="company" mandatory>
                <v-radio label="Sole proprietor (Self Employed)" value="self"></v-radio>
                <!-- <v-radio label="Sole Proprietor" value="sole"></v-radio> -->
                <v-radio label="Private Limited Company (Registered with CIPC)" value="limited"></v-radio>
            </v-radio-group>

            <a class="not-sure" target="_blank"
                href="https://www.moya.africa/post/how-should-you-register-as-self-employed-in-south-africa">
                Not Sure?
            </a>

            <v-text-field v-if="company == 'limited' || company == 'sole'" v-model="registration"
                label="Company Registration Number" hint="You can fill this in later" persistent-hint>
            </v-text-field>

            <v-btn block :loading="loader" @click="BusinessProfile(), loader = true">
                Submit
            </v-btn>
        </v-form>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";

    import {
        API
    } from "aws-amplify";

    export default {
        data: () => ({
            valid1: true,
            valid2: true,
            valid3: true,
            valid4: true,
            valid5: true,

            loader: false,

            step1: true,
            step2: false,
            step3: false,
            step4: false,
            step5: false,

            businessName: '',
            nameRules: [
                v => !!v || 'Name is required'
            ],

            cards: [{
                    id: 1,
                    title: 'Automobile',
                    icon: 'car_repair',
                    flex: 4
                },
                {
                    id: 2,
                    title: 'Creative',
                    icon: 'brush',
                    flex: 4
                },
                {
                    id: 3,
                    title: 'Healthcare',
                    icon: 'medical_services',
                    flex: 4
                },
                {
                    id: 4,
                    title: 'Food & Catering',
                    icon: 'restaurant',
                    flex: 4
                },
                {
                    id: 5,
                    title: 'Writing & Editing',
                    icon: 'edit',
                    flex: 4
                },
                {
                    id: 6,
                    title: 'Marketing',
                    icon: 'photo_camera',
                    flex: 4
                },
                {
                    id: 7,
                    title: 'Customer Service',
                    icon: 'emoji_people',
                    flex: 4
                },
                {
                    id: 8,
                    title: 'Professional Services',
                    icon: 'cases',
                    flex: 4
                },
                {
                    id: 9,
                    title: 'Administration',
                    icon: 'admin_panel_settings',
                    flex: 4
                },
                {
                    id: 10,
                    title: 'Consulting',
                    icon: 'group',
                    flex: 4
                },
                {
                    id: 11,
                    title: 'Management',
                    icon: 'supervised_user_circle',
                    flex: 4
                },
                {
                    id: 12,
                    title: 'Education',
                    icon: 'school',
                    flex: 4
                },
            ],
            active_el: 0,
            industry: null,

            service: [],
            items: [],

            time: null,

            company: null,
            registration: '',
        }),

        methods: {

            Welcome() {
                this.$router.push({
                    name: 'BusinessWelcome'
                }).catch(() => {})
            },

            activate: function (id, title) {
                this.active_el = id;
                this.industry = title
            },

            Name() {
                this.step1 = true;
                this.step2 = false;
                this.step3 = false;
                this.step4 = false;
                this.step5 = false;

            },

            Industry() {
                this.step1 = false;
                this.step2 = true;
                this.step3 = false;
                this.step4 = false;
                this.step5 = false;
            },

            Services() {
                this.step1 = false;
                this.step2 = false;
                this.step3 = true;
                this.step4 = false;
                this.step5 = false;
            },

            Time() {
                this.step1 = false;
                this.step2 = false;
                this.step3 = false;
                this.step4 = true;
                this.step5 = false;
            },

            Register() {
                this.step1 = false;
                this.step2 = false;
                this.step3 = false;
                this.step4 = false;
                this.step5 = true;
            },

            remove(item) {
                this.service.splice(this.service.indexOf(item), 1)
                this.service = [...this.service]
            },

            async BusinessProfile() {
                const apiName = "MoyaAPI";
                const apiPath = "/business";
                const apiRequest = {
                    body: {
                        businessName: this.businessName,
                        industry: this.industry,
                        services: this.service,
                        availability: this.time,
                        legalSetup: this.company,
                        regNumber: this.registration,
                    },
                };
                try {
                    const response = await API.post(apiName, apiPath, apiRequest);
                    console.log(response);

                    const user = await Auth.currentAuthenticatedUser();
                    await Auth.updateUserAttributes(user, {
                        'custom:form_business': '1',
                    })
                    this.$router.push({
                        name: 'BankWelcome'
                    })

                    this.loader = false;

                } catch (error) {
                    console.log(error.response);
                    this.loader = false;
                }
            },

        },

    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 20px 10px !important;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 3em !important;
        text-align: left !important;
    }

    .step {
        margin: 50px 0 10px 15px;
    }

    .step .v-icon {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 1em !important;
        text-align: left !important;
        margin-right: 5px;
    }

    .step .v-icon:first-of-type {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
    }

    .v-form {
        margin: 20px;
    }

    h1 {
        margin-bottom: 30px;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 30px;
    }

    .service {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        background-color: #5271ff !important;
        padding: 10px;
        height: 120px;
    }

    .active {
        background-color: #3bb54a !important;
        font-weight: bold;
    }

    .service-icon {
        color: #fff !important;
        font-size: 3em !important;
    }

    .service-title {
        margin: 0;
        margin-top: 5px;
        text-align: center;
        color: #fff;
        font-weight: 100;
        font-size: 0.8em;
    }

    .not-sure {
        /* text-align: center; */
        font-weight: bold;
        font-size: 0.9em;
        text-decoration: underline;
        color: #5271ff;

    }
</style>