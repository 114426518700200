<template>
    <v-container fluid>

        <v-snackbar v-model="snackbar" timeout="500000" rounded="pill" color="#DD1155">

            <p class="snack" v-if="tip == 2"> Tip: You can select multiple transactions </p>
            <p class="snack" v-else>Tap the <v-icon small>mdi-shape</v-icon> icon to categorise your transactions</p>


            <!-- <template v-slot:action="{ attrs }">
                <v-btn color="#DD1155" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template> -->
        </v-snackbar>

        <v-snackbar v-model="transnack" timeout="2000" absolute top color="#52ffa0">

            Successfully updated transactions

        </v-snackbar>

        <v-btn color="#DD1155" fab dark bottom left class="v-btn--example" v-if="selection.length || selection2.length"
            @click="snackbar = false; dialog = true">
            <v-icon>mdi-shape</v-icon>
        </v-btn>

        <v-app-bar flat :dark="selection.length != 0 || selection2.length != 0"
            :color="selection.length || selection2.length ? '#5271ff' : '#fff'" fixed>

            <v-toolbar flat color="transparent">
                <v-btn v-if="selection.length || selection2.length" icon @click="selection = []; selection2 = []">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-toolbar-title class="title" color="#5271ff">
                    {{ selection.length || selection2.length? `${selection.length || selection2.length} selected` : "Transactions" }}
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn v-if="!selection.length && !selection2.length" icon @click="Notifications()">
                    <v-badge color="#DD1155" dot :value="notification">
                        <v-icon class="account">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>

                <v-dialog v-if="selection.length || selection2.length" v-model="dialog" fullscreen hide-overlay
                    transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="snackbar = false">
                            <v-icon class="account">mdi-shape</v-icon>
                        </v-btn>
                    </template>
                    <v-card>


                        <v-toolbar dark color="#5271ff" class="pa-0">
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Choose a category</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <!-- <v-toolbar-items v-if="catText != 'Select Category'">
                                <v-btn v-if="catType == 'Uncategorized'" dark text
                                    @click="Categorise(model), dialog = false">
                                    Save
                                </v-btn>

                                <v-btn v-if="catType == 'Categorized'" dark text
                                    @click="ReCategorise(model), dialog = false">
                                    Save
                                </v-btn>
                            </v-toolbar-items> -->
                        </v-toolbar>

                        <div class="padding">


                            <h5 class="mx-5 mt-4 mb-2">
                                Type of transaction?
                            </h5>

                            <v-btn-toggle v-model="transactionType" rounded tile color="#5271ff" group class="mx-10">
                                <v-btn value="personal">
                                    Personal
                                </v-btn>

                                <v-btn tile value="business">
                                    Business
                                </v-btn>
                            </v-btn-toggle>

                            <span>

                                <h5 class="mx-5 mt-2 mb-2">
                                    Frequency?
                                </h5>

                                <v-btn-toggle v-model="transactionFrequency" tile color="#5271ff" group class="mx-10">
                                    <v-btn value="once-off">
                                        Once-off
                                    </v-btn>

                                    <v-btn value="recurring">
                                        Recurring
                                    </v-btn>
                                </v-btn-toggle>

                            </span>

                            <h5 class="mx-5 mt-2 mb-0">
                                Category?
                            </h5>

                            <v-menu class="menu">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="pa-4">
                                        <v-list rounded class="card px-2">
                                            <v-list-item v-bind="attrs" v-on="on" color="blue" tile>
                                                <v-list-item-icon>
                                                    <v-icon>{{catIcon}}</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{catText}}</v-list-item-title>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-btn icon>
                                                        <v-icon large>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </div>

                                </template>

                                <v-list max-height="500px" class="category-list">
                                    <v-list-item-group v-model="model" mandatory color="#5271ff">
                                        <v-list-item v-for="(item, i) in items" :key="i" @click="Category(item)">

                                            <v-list-item-icon>
                                                <v-icon v-text="item.icon"></v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.text"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>

                            </v-menu>

                            <h5 class="mx-5 mt-2 mb-0">
                                Sub Category?
                            </h5>


                            <div class="sub-category">
                                <v-combobox v-model="subCategory" :items="subCategoryItems" chips hide-selected
                                    clearable label="Sub Category" multiple hint="Press enter after each category"
                                    persistent-hint>
                                    <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                            @click:close="RemoveSub(item)">
                                            <strong>{{ item }}</strong>
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </div>

                            <v-btn class="button" v-if="catType == 'Uncategorized'" block
                                :disabled="catText == 'Select Category'" :loading="updater"
                                @click="Categorise(model), updater = true">
                                Save
                            </v-btn>

                            <v-btn class="button" v-if="catType == 'Categorized'" block
                                :disabled="catText == 'Select Category'" :loading="updater"
                                @click="ReCategorise(model), updater = true">
                                Save
                            </v-btn>

                        </div>

                    </v-card>
                </v-dialog>

            </v-toolbar>

            <template v-slot:extension v-if="!loader">
                <v-tabs v-model="tab" fixed-tabs>
                    <v-tabs-slider color="#5271ff"></v-tabs-slider>

                    <v-tab @click="Refresh('Uncategorized'); selection2 = []">
                        Uncategorized
                    </v-tab>
                    <v-tab @click="Refresh('Categorized'); selection = []">
                        Categorized
                    </v-tab>
                </v-tabs>
            </template>

        </v-app-bar>

        <v-chip-group v-if="!loader">

            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">

                    <v-chip dark link color="#5271ff" v-bind="attrs" v-on="on">

                        <v-icon left>
                            mdi-calendar-clock
                        </v-icon>

                        {{date}}
                    </v-chip>

                </template>
                <v-date-picker v-model="date" type="month" scrollable
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                        Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(date); RefreshDate()">
                        OK
                    </v-btn>
                </v-date-picker>
            </v-dialog>

            <div v-if="accounts.length > 0">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">

                        <v-chip dark link color="#5271ff" v-bind="attrs" v-on="on" class="mx-3">

                            <v-icon left>
                                mdi-account
                            </v-icon>

                            {{selectedAccount}}
                        </v-chip>

                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in accounts" :key="index" @click="ProcessBankData(index)">
                            <v-list-item-title>{{ accounts[index] }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

        </v-chip-group>

        <v-text-field v-if="!loader" v-model="search" append-icon="mdi-magnify" placeholder="Search">
        </v-text-field>

        <span v-if="loader">
            <v-skeleton-loader class="mx-auto" max-width="300"
                type="list-item, list-item, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line">
            </v-skeleton-loader>
        </span>

        <v-tabs-items v-if="!loader" v-model="tab">


            <v-tab-item>

                <p class="showing">Showing {{filterTransactions.length}} Transactions for {{monthName}}</p>

                <v-item-group v-model="selection" multiple>

                    <v-item v-for="transaction in filterTransactions" :key="transaction.transaction_id.S"
                        v-slot="{ active, toggle }">
                        <v-card :color="active ? '#5271ff' : ''" class="transaction" flat @click="toggle">
                            <div v-if="!active" class="transaction-left">
                                <v-icon color="#5271ff">{{transaction.Icon.S}}</v-icon>
                            </div>


                            <div v-if="active" class="transaction-left">
                                <v-icon color="#fff">mdi-checkbox-marked-outline</v-icon>
                            </div>

                            <div class="transaction-right">
                                <h3>{{transaction.Description.S}}</h3>
                                <p>{{(new Date(transaction.Transaction_Date.S)).toISOString().substring(0, 10)}}</p>
                            </div>

                            <div class="transaction-end">
                                <p :class="parseFloat(transaction.Amount.S) > 0 ? 'green' : 'red'">
                                    R{{parseFloat(transaction.Amount.S)}}</p>
                            </div>

                        </v-card>
                    </v-item>



                </v-item-group>

            </v-tab-item>

            <v-tab-item>

                <p class="showing">Showing {{filterCategorised.length}} Transactions for {{monthName}}</p>

                <v-item-group v-model="selection2" multiple>

                    <v-item v-for="transaction in filterCategorised" :key="transaction.transaction_id.S"
                        v-slot="{ active, toggle }">
                        <v-card :color="active ? '#5271ff' : ''" class="transaction" flat @click="toggle">
                            <div v-if="!active" class="transaction-left">
                                <v-icon color="#5271ff">{{transaction.Icon.S}}</v-icon>
                            </div>


                            <div v-if="active" class="transaction-left">
                                <v-icon color="#fff">mdi-checkbox-marked-outline</v-icon>
                            </div>

                            <div class="transaction-right">
                                <h3>{{transaction.Description.S}}</h3>
                                <p>{{(new Date(transaction.Transaction_Date.S)).toISOString().substring(0, 10)}}</p>
                                <v-chip color="#FF0066" dark class="mt-2 mr-1" x-small>{{transaction.Category.S}}
                                </v-chip>
                                <v-chip color="#FF0066" dark class="mt-2 mr-1" x-small>
                                    {{transaction.Type.S}}</v-chip>
                                <v-chip color="#FF0066" dark class="mt-2 mr-1" x-small>
                                    {{transaction.Frequency.S}}</v-chip>
                                <template v-for="(sub, index) in transaction.Sub_Categories.SS">
                                    <v-chip color="#FAFF00" class="mt-2 mr-1" x-small :key="sub.id"
                                        v-if="transaction.Sub_Categories.SS[index] != 'No Subcategory'">
                                        {{transaction.Sub_Categories.SS[index]}}</v-chip>
                                </template>

                            </div>

                            <div class="transaction-end">
                                <p :class="parseFloat(transaction.Amount.S) > 0 ? 'green' : 'red'">
                                    R{{parseFloat(transaction.Amount.S)}}</p>
                            </div>

                        </v-card>
                    </v-item>



                </v-item-group>
            </v-tab-item>



        </v-tabs-items>





    </v-container>
</template>

<script>
    import {
        API
    } from "aws-amplify";

    export default {

        components: {

        },

        data: () => ({

            notification: null,
            businessID: null,
            stitchData: null,
            catType: 'Uncategorized',
            loader: true,
            updater: false,
            snackbar: false,
            transnack: false,
            accountIndex: null,
            tip: 1,

            selection: [],
            selection2: [],
            dialog: false,
            dialog2: false,
            search: '',
            search2: '',
            tab: null,

            date: new Date().toISOString().substr(0, 7),
            menu: false,
            modal: false,
            monthName: '',

            amenities: [1, 4],
            neighborhoods: [1],

            bankData: null,

            transactions: [],
            tempTransactions: [],

            categorisedTransactions: [],

            text: 'center',

            personalItems: [{
                    icon: 'mdi-food',
                    text: 'Food & Drink',
                },
                {
                    icon: 'mdi-train-car',
                    text: 'Travel',
                },
                {
                    icon: 'mdi-phone',
                    text: 'Utility',
                },
                {
                    icon: 'mdi-account-switch',
                    text: 'Transfer',
                },
                {
                    icon: 'mdi-gift-open',
                    text: 'Leisure',
                },
                {
                    icon: 'mdi-cart',
                    text: 'Groceries',
                },
                {
                    icon: 'mdi-finance',
                    text: 'Finances',
                },
                {
                    icon: 'mdi-account-heart',
                    text: 'Personal Care',
                },
                {
                    icon: 'mdi-medical-bag',
                    text: 'Health Care',
                }, {
                    icon: 'mdi-square-circle',
                    text: 'General',
                },
                {
                    icon: 'mdi-playlist-check',
                    text: 'Subscription',
                }
            ],

            businessItems: [{
                    icon: 'mdi-currency-usd',
                    text: 'Income',
                },
                {
                    icon: 'mdi-cash',
                    text: 'Capital',
                }, {
                    icon: 'mdi-laptop',
                    text: 'Equipment',
                },
                {
                    icon: 'mdi-food',
                    text: 'Food & Drink',
                },
                {
                    icon: 'mdi-train-car',
                    text: 'Travel',
                },
                {
                    icon: 'mdi-phone',
                    text: 'Utility',
                },
                {
                    icon: 'mdi-account-switch',
                    text: 'Transfer',
                },
                {
                    icon: 'mdi-finance',
                    text: 'Finances',
                },
                {
                    icon: 'mdi-square-circle',
                    text: 'General',
                },
                {
                    icon: 'mdi-point-of-sale',
                    text: 'Cost of Sales',
                },
                {
                    icon: 'mdi-bulldozer',
                    text: 'Development',
                },
                {
                    icon: 'mdi-playlist-check',
                    text: 'Subscription',
                },
                {
                    icon: 'mdi-currency-usd-off',
                    text: 'Tax',
                },
            ],

            items: [],
            model: 0,

            catIcon: 'mdi-filter-variant',
            catText: 'Select Category',

            subCategory: [],
            subCategoryItems: [],

            transactionType: 'personal',
            transactionFrequency: 'once-off',

            filterCategories: [],
            filterSubCategories: [],

            accounts: [],
            selectedAccount: ''
        }),

        methods: {

            Notifications() {

                this.$store.dispatch('Notification', false);

                this.$router.push({
                    name: 'Notifications'
                }).catch(() => {})
            },

            RemoveSub(item) {
                this.subCategory.splice(this.subCategory.indexOf(item), 1)
                this.subCategory = [...this.subCategory]
            },

            Category(item) {
                // console.log(item)
                this.catIcon = item.icon;
                this.catText = item.text;
            },

            Categorise(model) {

                this.subCategoryItems = this.subCategory;

                if (this.subCategory.length == 0) {
                    this.subCategory.push("No Subcategory")
                }

                for (let i = 0; i < this.selection.length; i++) {

                    this.filterTransactions[this.selection[i]].Icon.S = this.items[model].icon;
                    this.filterTransactions[this.selection[i]]['category'] = this.items[model].text;
                    this.filterTransactions[this.selection[i]]['transactionType'] = this.transactionType;
                    this.filterTransactions[this.selection[i]]['transactionFrequency'] = this.transactionFrequency;
                    this.filterTransactions[this.selection[i]]['subCatorgories'] = this.subCategory;
                    // this.filterTransactions[this.selection[i]]['account'] = this.selectedAccount;
                }

                this.selection = [];
                this.subCategory = [];

                this.PatchTransactions()
                // this.Refresh()
            },

            ReCategorise(model) {

                this.subCategoryItems = this.subCategory;

                if (this.subCategory.length == 0) {
                    this.subCategory.push("No Subcategory")
                }

                for (let i = 0; i < this.selection2.length; i++) {

                    this.filterCategorised[this.selection2[i]].Icon = {
                        "S": this.items[model].icon
                    };
                    this.filterCategorised[this.selection2[i]].Category = {
                        "S": this.items[model].text
                    };
                    this.filterCategorised[this.selection2[i]].Type = {
                        "S": this.transactionType
                    };
                    this.filterCategorised[this.selection2[i]].Frequency = {
                        "S": this.transactionFrequency
                    };
                    this.filterCategorised[this.selection2[i]].Sub_Categories = {
                        "SS": this.subCategory
                    };
                    this.filterCategorised[this.selection2[i]].Account_Name = {
                        "S": this.selectedAccount
                    };
                }

                this.selection2 = [];
                this.subCategory = [];

                this.RePatchTransactions()
                // this.Refresh()
            },

            async PatchTransactions() {

                this.loader = true;

                let categorised = [];

                for (let i = 0; i < this.transactions.length; i++) {
                    const element = this.transactions[i];

                    if (element['Icon'].S != 'mdi-plus-box') {
                        categorised.push(element)
                    }

                }

                // console.log(categorised)

                const apiName = "MoyaAPI";
                const apiPath = "/transaction";
                const apiRequest = {
                    body: {
                        businessId: this.businessID,
                        transactions: categorised
                    },
                };
                try {
                    const response = await API.patch(apiName, apiPath, apiRequest);
                    // console.log(response);

                    if (response.error) {

                        console.log(response.error)
                        this.loader = false;

                    } else {

                        this.transnack = true;
                        this.GetCategorisedTransactions();
                    }

                } catch (error) {
                    console.log(error.response);
                    this.loader = false;
                }
            },

            async RePatchTransactions() {

                this.loader = true;

                let categorised = [];

                for (let i = 0; i < this.filterCategorised.length; i++) {
                    const element = this.filterCategorised[i];

                    let transaction = {}

                    transaction["transaction_id"] = element.transaction_id;
                    transaction["Icon"] = element.Icon;
                    transaction["category"] = element.Category.S;
                    transaction["transactionType"] = element.Type.S;
                    transaction["transactionFrequency"] = element.Frequency.S;
                    transaction["subCatorgories"] = element.Sub_Categories.SS;

                    categorised.push(transaction)

                }

                // console.log(categorised)

                const apiName = "MoyaAPI";
                const apiPath = "/transaction";
                const apiRequest = {
                    body: {
                        businessId: this.businessID,
                        transactions: categorised
                    },
                };
                try {
                    const response = await API.patch(apiName, apiPath, apiRequest);
                    console.log(response);
                    this.GetCategorisedTransactions();
                    // this.loader = false;
                    this.transnack = true;

                } catch (error) {
                    console.log(error.response);
                    this.loader = false;
                }
            },

            async GetUser() {
                const apiName = "MoyaAPI";
                const apiPath = "/user";
                try {
                    const response = await API.get(apiName, apiPath);
                    this.businessID = response.response.Item.Business_Ids.SS[0];



                } catch (error) {
                    console.log(error.response);
                }
            },

            RefreshDate() {

                this.transactions = this.TransactionsData;
                this.ProcessBankData(this.accounts.indexOf(this.selectedAccount))

                this.$store.dispatch('FilterDate', this.date);
            },


            StartProcessing() {

                this.modal = false;
                this.accounts = [];

                for (let i = 0; i < this.bankData.Items.length; i++) {
                    const element = this.bankData.Items[i].Account_Name.S;

                    this.accounts.push(element)
                }

                this.ProcessBankData(this.accountIndex);

            },

            ProcessBankData(name) {

                this.$store.dispatch('SelectedAccount', name);

                this.transactions = this.TransactionsData

                this.selectedAccount = this.bankData.Items[name].Account_Name.S;

                this.ProcessTransactions(this.transactions);

            },

            ProcessTransactions(data) {

                this.transactions = [];
                this.categorisedTransactions = [];

                let transactions = [];
                transactions = data;

                for (let j = 0; j < transactions.Items.length; j++) {
                    const transaction = transactions.Items[j];

                    if (transaction.Account_Name.S == this.selectedAccount && new Date(transaction.Transaction_Date.S)
                        .getMonth() == new Date(this.date).getMonth()) {
                        if (transaction.Icon.S == 'mdi-plus-box') {
                            this.transactions.push(transaction)
                        } else {
                            this.categorisedTransactions.push(transaction)
                            // console.log(transaction)
                        }
                    }
                }

                // console.log(this.transactions)

                this.loader = false

            },

            async GetCategorisedTransactions() {

                const apiName = "MoyaAPI";
                const apiPath = "/user_transaction";
                const apiRequest = {
                    queryStringParameters: {
                        businessId: this.businessID,
                    },
                };
                try {

                    this.categorisedTransactions = []

                    const response = await API.get(apiName, apiPath, apiRequest);

                    console.log(response)

                    this.transactions = response.responseTransactions;

                    this.loader = false;

                    this.updater = false;

                    this.dialog = false;

                    this.$store.dispatch('TransactionsData', response.responseTransactions);

                    this.ProcessBankData(this.accounts.indexOf(this.selectedAccount))




                } catch (error) {
                    console.log(error);
                }
            },

            ProcessCategorised(data) {

                this.categorisedTransactions = []

                let year = this.date.substring(0, 4);
                let month = parseInt(this.date.slice(-2)).toString();

                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    const yearC = new Date(data[i].Transaction_Date.S).getFullYear();
                    const monthC = new Date(data[i].Transaction_Date.S).getMonth() + 1;

                    if (element.Account_Name.S == this.selectedAccount && yearC == year && monthC == month) {

                        this.categorisedTransactions.push(element)
                    }

                }
                this.tempTransactions = this.transactions.slice();

                this.FilteredTransactions()
            },

            FilteredTransactions() {


                for (let i = 0; i < this.tempTransactions.length; i++) {

                    const stitchID = this.tempTransactions[i].transaction_id.S;

                    for (let j = 0; j < this.categorisedTransactions.length; j++) {
                        const categorisedID = this.categorisedTransactions[j].transaction_id.S;

                        if (stitchID == categorisedID && this.tempTransactions[i].Description.S == this
                            .categorisedTransactions[j].Description.S) {

                            this.transactions.splice(this.transactions.indexOf(this.tempTransactions[i]), 1)
                        }

                    }
                }

                this.loader = false;
            },

            Refresh(type) {

                // this.transactions = this.TransactionsData;

                // console.log(this.transactions)

                // this.ProcessBankData(this.accounts.indexOf(this.selectedAccount))

                this.catType = type;
                // this.loader = true;
            },
        },

        mounted() {
            setInterval(() => {
                if (this.tip == 1) this.tip++
                else this.tip = 1
            }, 5000)
        },

        computed: {
            filterTransactions() {
                return this.transactions.filter(transaction => {
                    return transaction.Description.S.toLowerCase().includes(this.search.toLowerCase());
                });
            },

            filterCategorised() {
                return this.categorisedTransactions.filter(transaction => {
                    return transaction.Description.S.toLowerCase().includes(this.search.toLowerCase());
                });
            },

            TransactionsData() {
                return this.$store.getters.TransactionsData
            },

            BankData() {
                return this.$store.getters.BankData
            },

            BusinessID() {
                return this.$store.getters.BusinessID
            },

            SelectedAccount() {
                return this.$store.getters.SelectedAccount
            },

            FilterDate() {
                return this.$store.getters.FilterDate
            },

            Notification() {
                return this.$store.getters.Notification
            },
        },

        created() {

            document.title = 'Moya | Transactions'

            this.notification = this.Notification;

            this.items = this.personalItems;

            if (this.FilterDate != null) {
                this.date = this.FilterDate;
            }

            if (this.TransactionsData != null || this.BankData != null || this.BusinessID != null) {

                this.transactions = this.TransactionsData;
                this.bankData = this.BankData;
                this.businessID = this.BusinessID;
                this.accountIndex = this.SelectedAccount;

                this.StartProcessing()

            } else {
                this.$router.push({
                    name: 'Overview'
                }).catch(() => {})
            }

            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
                "October", "November", "December"
            ];

            this.monthName = months[new Date(this.date).getMonth()]; // "July" (or current month)

        },


        watch: {
            transactionType: function () {
                if (this.transactionType == "personal") {
                    this.items = this.personalItems
                    this.catIcon = 'mdi-filter-variant';
                    this.catText = 'Select Category';
                } else if (this.transactionType == "business") {
                    this.items = this.businessItems
                    this.catIcon = 'mdi-filter-variant';
                    this.catText = 'Select Category';
                }
            },

            selection: function () {
                if (this.selection.length > 0) {
                    this.snackbar = true;
                } else {
                    this.snackbar = false
                }
            },

            selection2: function () {
                if (this.selection2.length > 0) {
                    this.snackbar = true;
                } else {
                    this.snackbar = false
                }
            },

            date: function () {
                let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
                    "October", "November", "December"
                ];

                this.monthName = months[new Date(this.date).getMonth()]; // "July" (or current month)
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 20px !important;
    }

    .margin {
        /* margin-top: 50px; */
    }

    /* .v-list--rounded {
        padding: 0px !important;
    }

    .v-list {
        padding: 0px !important;
    } */

    .padding {
        padding: 10px 20px 0 20px !important;
    }

    .v-sheet.v-card {
        border-radius: 0px;
    }

    .v-dialog--fullscreen>.v-card {
        min-height: 100%;
        min-width: 100%;
        margin: 0 !important;
        /* padding: 0px 20px 0 20px !important; */
        /* background-color: #5271ff; */
    }

    .v-toolbar__content,
    .v-toolbar__extension {
        padding: 4px 4px;
    }

    .container {
        padding: 130px 30px !important;
    }

    .showing {
        font-weight: bold;
        font-size: 0.9em;
    }

    .transaction {
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        padding: 10px 10px;
        border-radius: 15px;
        margin: 10px 0 10px 0;
    }

    .transaction-left {
        padding: 0 5px;
    }

    .transaction-left i {
        color: #5271ff;
        font-size: 2em;
        margin-right: 10px;
    }

    .transaction-right {
        width: 50%;
    }

    .red {

        color: #ff5252;
        background-color: transparent !important;
    }

    .green {

        color: #52ffa0;
        background-color: transparent !important;
    }

    .transaction-end {
        width: 30%;
    }

    .transaction-end p {
        /* color: #ff5252; */
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: right;
    }

    .transaction-right h3 {
        font-size: 0.8em;
    }

    .transaction-right p {
        font-size: 0.7em;
        margin: 3px 0 0 0;
    }

    h5 {
        /* margin: 15px 15px; */
        font-size: 16px;
    }

    .sub-category {
        padding: 0 25px 20px !important;
    }

    .card {
        background-color: #CED2FE !important;
        border-radius: 20px !important;
        color: #000;
    }

    .menu {
        z-index: 5000;
    }

    .category-list {
        overflow-y: auto;
    }

    .v-btn--example {
        bottom: 65px;
        right: 10px;
        position: fixed !important;
        /* margin: 0 0 16px 16px; */
        z-index: 5;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        /* margin-top: 10px; */
    }

    .snack {
        text-align: center !important;
        margin: 0;
    }
</style>