<template>
    <v-container fluid>

        <img :src="require('../assets/business.svg')" alt="">

        <p>Tell us a little bit about your business</p>

        <v-btn block @click="Continue()">Continue</v-btn>

    </v-container>
</template>

<script>

    export default {
        data: () => ({

        }),

        methods: {

            Continue() {
                this.$router.push({
                    name: 'BusinessSetup'
                }).catch(() => {})
            },

        },

        created() {

        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 40px 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    h1 {
        text-align: center;
        font-weight: bold;
    }

    p {
        text-align: center;
        margin-top: 10px;
    }

    img {
        width: 250px;
        margin: 30px;
    }


</style>