<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>

            <v-toolbar-title class="title">Support</v-toolbar-title>

            <v-spacer></v-spacer>



        </v-app-bar>

        <v-card flat>
            <v-card-title>
                FAQs & Feedback
            </v-card-title>

            <v-card-subtitle class="my-2">We understand the challenges that come with freelancing and being
                self-employed. We are continuously improving our platform to create a better user experience. Drop us a
                message for questions or feedback!</v-card-subtitle>


            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-chip class="my-2 mr-2" color="#5271ff" text-color="#fff"
                    @click="OpenLink('https://uncovered-potassium-b15.notion.site/b5606d5d6bbb44eab5544327ef49e2ad?v=ac440ac2c8184229bd70c7eebc38c1d5')">
                    FAQs
                    <v-icon right small>
                        mdi-open-in-new
                    </v-icon>
                </v-chip>
                <v-chip class="mr-2" color="#5271ff" text-color="#fff"
                    @click="OpenLink('https://airtable.com/shrisMQuq0UkUrkvc')">
                    Send Feedback
                    <v-icon right small>
                        mdi-open-in-new
                    </v-icon>
                </v-chip>
                <v-chip color="#5271ff" text-color="#fff" @click="OpenLink('https://airtable.com/shr9orwMQsL3bLEkB')">
                    Feature Request
                    <v-icon right small>
                        mdi-open-in-new
                    </v-icon>
                </v-chip>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
    export default {
        data: () => ({
            tab: null,
            loader: false,
        }),

        methods: {

            OpenLink(link) {
                window.open(link);
            },
        },

        created() {
            document.title = 'Moya | Support'
        },

    }
</script>

<style scoped>
    .container {
        padding: 60px 30px 60px !important;
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    h4 {
        margin-bottom: 10px;
    }

    h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        /* margin-top: 10px; */
        width: 100%;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }
</style>