<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-toolbar fixed flat>
                <v-toolbar-title class="title">Analytics</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="Notifications()">
                    <v-badge color="#DD1155" dot :value="notification">
                        <v-icon class="account">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </v-toolbar>

            <template v-slot:extension>
                <v-tabs v-model="tab" fixed-tabs v-if="!loader2">
                    <v-tabs-slider color="#5271ff"></v-tabs-slider>

                    <v-tab @click="RefreshType('personal'), overview = false">
                        Personal
                    </v-tab>
                    <v-tab @click="RefreshType('business'), overview = false">
                        Business
                    </v-tab>
                    <v-tab @click="overview = true">
                        Overview
                    </v-tab>
                </v-tabs>
            </template>

        </v-app-bar>

        <span v-if="loader">
            <v-skeleton-loader class="mx-auto" max-width="300" type="list-item, list-item, image, list-item, image">
            </v-skeleton-loader>
        </span>

        <span v-show="!loader">

            <v-chip-group>

                <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">

                        <v-chip dark link color="#5271ff" v-bind="attrs" v-on="on">

                            <v-icon left>
                                mdi-calendar-clock
                            </v-icon>

                            {{date}}
                        </v-chip>

                    </template>
                    <v-date-picker v-model="date" type="month" scrollable
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">
                            Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(date); RefreshDate()">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>

                <div v-if="accounts.length > 0">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">

                            <v-chip dark link color="#5271ff" v-bind="attrs" v-on="on" class="mx-3">

                                <v-icon left>
                                    mdi-account
                                </v-icon>

                                {{selectedAccount}}
                            </v-chip>

                        </template>

                        <v-list>
                            <v-list-item v-for="(item, index) in accounts" :key="index" @click="ProcessBankData(index)">
                                <v-list-item-title>{{ accounts[index] }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

            </v-chip-group>

            <p class="showing">Showing Analytics for {{monthName}}</p>

            <h4 v-show="overview">Categorisation</h4>

            <v-card v-show="overview" class="graph-card">
                <VueApexCharts type="radialBar" ref="bar" :options="categorisedChartOptions" :series="categorisedChart">
                </VueApexCharts>
            </v-card>

            <h4 v-show="overview">Personal vs Business</h4>

            <v-card v-show="overview" class="graph-card">
                <VueApexCharts type="bar" ref="pie" :options="pbBarOptions" :series="pbBarSeries">
                </VueApexCharts>
            </v-card>

            <h4 v-show="!overview">Income</h4>
            <v-card v-show="!overview" class="graph-card">
                <VueApexCharts type="pie" ref="pie" :options="incomeChartOptions" :series="incomePie"
                    v-if="incomePie.length > 0">
                </VueApexCharts>
                <p class="no-data" v-if="incomePie.length == 0">You have not categorised any transactions for this
                    account or month</p>
            </v-card>

            <v-card v-if="incomePie.length > 0" v-show="!overview" class="graph-card">
                <VueApexCharts type="bar" ref="pie" :options="incomeBarOptions" :series="incomeBarSeries">
                </VueApexCharts>
            </v-card>

            <h4 v-show="!overview">Expenses</h4>
            <v-card v-show="!overview" class="graph-card">
                <VueApexCharts type="pie" :options="expenseChartOptions" :series="expensePie"
                    v-if="expensePie.length > 0">
                </VueApexCharts>
                <p class="no-data" v-if="expensePie.length == 0">You have not categorised any transactions for this
                    account or month</p>
            </v-card>

            <v-card v-if="expensePie.length > 0" v-show="!overview" class="graph-card">
                <VueApexCharts type="bar" ref="pie" :options="expenseBarOptions" :series="expenseBarSeries">
                </VueApexCharts>
            </v-card>

            <!-- <h4>Overall</h4>

        <VueApexCharts type="line" height="350" :options="chartOptions2" :series="series2"></VueApexCharts> -->

        </span>
    </v-container>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {

        components: {
            VueApexCharts
        },

        data: () => ({

            notification: null,
            tab: 0,
            monthName: '',
            loader: false,
            loader2: false,
            categorisationType: 'personal',
            businessID: null,
            stitchData: null,
            dialog: false,
            date: new Date().toISOString().substr(0, 7),
            menu: false,
            modal: false,
            overview: false,
            accountIndex: null,

            accounts: [],
            selectedAccount: '',
            transactions: [],
            categorisedTransactions: [],

            totalMonthlyTransactions: [],
            personalCategorisedTransactions: [],
            businessCategorisedTransactions: [],

            incomeLabels: [],
            expenseLabels: [],

            incomeAmounts: {},
            expenseAmounts: {},

            //---------------charts------------------

            //radial 

            categorisedChart: [50],
            categorisedChartOptions: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                    offsetY: -10
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                                color: "#5271ff",
                                offsetY: 40
                            },
                            value: {
                                offsetY: 0,
                                fontSize: '22px',
                                color: undefined,
                                formatter: function (val) {
                                    return val + "%";
                                }
                            }
                        },

                        track: {
                            background: "#5271ff",
                            strokeWidth: '5%',
                            margin: 5, // margin is in pixels
                        },


                    }
                },
                fill: {
                    colors: '#5271ff'
                },
                labels: ['Categorised'],
            },


            //income pie
            incomePie: [44, 55, 13, 43],
            incomeChartOptions: {
                chart: {
                    type: 'pie',
                    height: 250
                },
                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
                labels: [],
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#5271ff'
                    }
                },
                noData: {
                    text: 'no data yet'
                },

                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {

                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            //expense pie
            expensePie: [44, 55, 13, 43],
            expenseChartOptions: {
                chart: {

                    type: 'pie',
                },


                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
                labels: [],
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#5271ff'
                    }
                },
                noData: {
                    text: 'no data yet'
                },

                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {

                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            value: [
                423,
                446,
                675,
                510,
                590,
                610,
                760,
            ],

            series2: [{
                name: "Desktops",
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
            }],
            chartOptions2: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: '',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                }
            },


            //bar options
            incomeBarSeries: [{
                data: []
            }],
            incomeBarOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#5271ff'
                    }
                },
            },

            expenseBarSeries: [{
                data: []
            }],
            expenseBarOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#5271ff'
                    }
                },
            },

            pbBarSeries: [{
                data: []
            }],
            pbBarOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#000']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  R" + val
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                xaxis: {
                    categories: ['Personal Income', 'Personal Expenses', 'Business Income',
                        'Business Expenses'
                    ],
                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#5271ff'
                    }
                },

            },




        }),

        methods: {
            Notifications() {
                this.$store.dispatch('Notification', false);

                this.$router.push({
                    name: 'Notifications'
                }).catch(() => {})
            },

            RefreshType(type) {
                this.categorisationType = type
                this.ProcessBankData(this.accounts.indexOf(this.selectedAccount))
            },

            RefreshDate() {
                this.ProcessBankData(this.accounts.indexOf(this.selectedAccount))

                this.$store.dispatch('FilterDate', this.date);
            },

            StartProcessing() {

                this.accounts = [];
                this.loader = true;



                for (let i = 0; i < this.bankData.Items.length; i++) {
                    const element = this.bankData.Items[i].Account_Name.S;

                    this.accounts.push(element)
                }

                this.ProcessBankData(this.accountIndex);
            },


            ProcessBankData(name) {

                this.$store.dispatch('SelectedAccount', name);

                this.incomeLabels = [];
                this.expenseLabels = [];

                this.transactions = this.TransactionsData;

                this.selectedAccount = this.bankData.Items[name].Account_Name.S;



                this.incomeChartOptions.labels = [];
                this.incomePie = [];
                this.incomeLabels = []

                this.expenseChartOptions.labels = [];
                this.expensePie = [];
                this.expenseLabels = [];

                this.ProcessTransactions(this.transactions);

            },

            ProcessTransactions(data) {

                this.transactions = [];
                this.totalMonthlyTransactions = [];
                let transactions = data;

                if (transactions != null) {

                    for (let j = 0; j < transactions.Items.length; j++) {
                        const element = transactions.Items[j];

                        if (element.Icon.S != "mdi-plus-box") {
                            this.transactions.push(element)
                        }

                    }

                    let year = this.date.substring(0, 4);
                    let month = parseInt(this.date.slice(-2)).toString();

                    for (let i = 0; i < transactions.Items.length; i++) {
                        const element = transactions.Items[i];
                        const yearC = new Date(transactions.Items[i].Transaction_Date.S).getFullYear();
                        const monthC = new Date(transactions.Items[i].Transaction_Date.S).getMonth() + 1;

                        if (element.Account_Name.S == this.selectedAccount && yearC == year && monthC == month) {

                            this.totalMonthlyTransactions.push(element);

                        }

                    }

                    this.ProcessCategorised(this.transactions)
                } else {
                    console.log('no transactions')
                    this.loader = false
                    this.loader2 = false
                    this.transactions = []
                    // this.$refs.bar.updateSeries(
                    //     [0]
                    // )
                }



            },

            ProcessCategorised(data) {

                this.categorisedTransactions = []
                this.businessCategorisedTransactions = []
                this.personalCategorisedTransactions = []


                let year = this.date.substring(0, 4);
                let month = parseInt(this.date.slice(-2)).toString();

                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    const yearC = new Date(data[i].Transaction_Date.S).getFullYear();
                    const monthC = new Date(data[i].Transaction_Date.S).getMonth() + 1;

                    if (element.Account_Name.S == this.selectedAccount && yearC == year && monthC == month && element
                        .Type.S == this.categorisationType) {

                        this.categorisedTransactions.push(element)


                        if (parseInt(element.Amount.S) < 0) {
                            this.expenseLabels.push(element.Category.S)

                            if (this.expenseAmounts[element.Category.S]) {
                                this.expenseAmounts[element.Category.S] += parseInt(element.Amount.S)
                            } else {
                                this.expenseAmounts[element.Category.S] = parseInt(element.Amount.S)
                            }

                        } else {
                            this.incomeLabels.push(element.Category.S)

                            if (this.incomeAmounts[element.Category.S]) {
                                this.incomeAmounts[element.Category.S] += parseInt(element.Amount.S)
                            } else {
                                this.incomeAmounts[element.Category.S] = parseInt(element.Amount.S)
                            }


                        }

                    }

                    if (element.Account_Name.S == this.selectedAccount && yearC == year && monthC == month) {

                        if (element.Type.S == 'personal') {
                            this.personalCategorisedTransactions.push(element)
                        } else if (element.Type.S == 'business') {
                            this.businessCategorisedTransactions.push(element)
                        }

                    }

                }

                let personalIncome = 0;
                let personalExpense = 0;

                for (let h = 0; h < this.personalCategorisedTransactions.length; h++) {
                    const element = parseInt(this.personalCategorisedTransactions[h].Amount.S);

                    if (element > 0) {
                        personalIncome += element;
                    } else {
                        personalExpense += element;
                    }

                }

                let businessIncome = 0;
                let businessExpense = 0;

                for (let h = 0; h < this.businessCategorisedTransactions.length; h++) {
                    const element = parseInt(this.businessCategorisedTransactions[h].Amount.S);

                    if (element > 0) {
                        businessIncome += element;
                    } else {
                        businessExpense += element;
                    }

                }

                this.pbBarSeries[0]['data'] = [personalIncome, Math.abs(personalExpense), businessIncome, Math.abs(
                    businessExpense)]


                //percent chart

                let percent = 0;

                if (this.personalCategorisedTransactions.length + this.businessCategorisedTransactions.length <= 0 &&
                    this.totalMonthlyTransactions.length > 0) {

                    percent = 0

                } else if (this.personalCategorisedTransactions.length + this.businessCategorisedTransactions.length >
                    0) {

                    percent = (((this.personalCategorisedTransactions.length + this.businessCategorisedTransactions
                        .length) / this.totalMonthlyTransactions.length) * 100).toFixed(0);

                } else {
                    percent = 0
                }

                // console.log(percent)

                this.categorisedChart = [percent]


                //update income
                this.incomeChartOptions.labels = [];
                let incomeLabels = []
                let incomeCount = []
                this.incomeBarSeries[0]['data'] = []

                for (let j = 0; j < this.incomeLabels.length; j++) {
                    // const element = this.incomeLabels[j];

                    if (!incomeLabels.includes(this.incomeLabels[j])) {
                        incomeLabels.push(this.incomeLabels[j])
                    }

                }

                for (let k = 0; k < incomeLabels.length; k++) {
                    const elementK = incomeLabels[k];

                    incomeCount[k] = 0

                    for (let l = 0; l < this.incomeLabels.length; l++) {
                        const elementL = this.incomeLabels[l];

                        if (elementK == elementL) {
                            incomeCount[k] += 1
                        }
                    }
                }

                this.incomeChartOptions = {
                    ...this.incomeChartOptions,
                    ...{
                        labels: incomeLabels,
                    },
                    ...{
                        chart: {
                            type: 'pie',
                            height: 250
                        },
                    }
                }

                this.incomePie = incomeCount;

                for (let l = 0; l < incomeLabels.length; l++) {
                    const elementL = incomeLabels[l];

                    this.incomeBarSeries[0]['data'].push(this.incomeAmounts[elementL])
                }

                this.incomeBarOptions = {
                    ...this.incomeBarOptions,
                    chart: {
                        type: 'bar',
                        height: 150
                    },
                    xaxis: {
                        categories: incomeLabels,
                    }
                }

                //update expenses
                this.expenseChartOptions.labels = [];
                let expenseLabels = []
                let expenseCount = []
                this.expenseBarSeries[0]['data'] = []

                for (let j = 0; j < this.expenseLabels.length; j++) {
                    // const element = this.expenseLabels[j];

                    if (!expenseLabels.includes(this.expenseLabels[j])) {
                        expenseLabels.push(this.expenseLabels[j])
                    }

                }

                for (let k = 0; k < expenseLabels.length; k++) {
                    const elementK = expenseLabels[k];

                    expenseCount[k] = 0

                    for (let l = 0; l < this.expenseLabels.length; l++) {
                        const elementL = this.expenseLabels[l];

                        if (elementK == elementL) {
                            expenseCount[k] += 1
                        }
                    }
                }

                this.expenseChartOptions = {
                    ...this.expenseChartOptions,
                    ...{
                        labels: expenseLabels,
                    },
                    ...{
                        chart: {
                            type: 'pie',
                            height: 250
                        },
                    }
                };
                this.expensePie = expenseCount;

                // this.incomeChartOptions.responsive[0].options.legend.position = 'bottom';

                for (let l = 0; l < expenseLabels.length; l++) {
                    const elementL = expenseLabels[l];

                    this.expenseBarSeries[0]['data'].push(Math.abs(this.expenseAmounts[elementL]))
                }

                this.expenseBarOptions = {
                    ...this.expenseBarOptions,
                    chart: {
                        type: 'bar',
                        height: 150
                    },
                    xaxis: {
                        categories: expenseLabels,
                    }
                }

                this.loader = false;
                this.loader2 = false;
            },

        },

        created() {
            // this.GetUser()
            // this.TransactionStitchQuery()

            document.title = 'Moya | Analytics'

            this.notification = this.Notification;

            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
                "October", "November", "December"
            ];

            this.monthName = months[new Date(this.date).getMonth()]; // "July" (or current month)

            if (this.FilterDate != null) {
                this.date = this.FilterDate;
            }

            if (this.TransactionsData != null || this.BankData != null || this.BusinessID != null) {

                // console.log(this.TransactionsData)
                // console.log(this.BankData)
                // console.log("BusinessID: " + this.BusinessID)

                this.transactions = this.TransactionsData;
                this.bankData = this.BankData;
                this.businessID = this.BusinessID;
                this.accountIndex = this.SelectedAccount;

                this.StartProcessing()

            } else {
                this.$router.push({
                    name: 'Overview'
                }).catch(() => {})
            }
        },

        watch: {
            date: function () {
                let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
                    "October", "November", "December"
                ];

                this.monthName = months[new Date(this.date).getMonth()]; // "July" (or current month)
            }
        },

        computed: {
            TransactionsData() {
                return this.$store.getters.TransactionsData
            },

            BankData() {
                return this.$store.getters.BankData
            },

            BusinessID() {
                return this.$store.getters.BusinessID
            },

            SelectedAccount() {
                return this.$store.getters.SelectedAccount
            },

            FilterDate() {
                return this.$store.getters.FilterDate
            },

            Notification() {
                return this.$store.getters.Notification
            },
        }
    }
</script>

<style scoped>
    .container {
        padding: 130px 30px !important;
    }

    h1 {
        font-weight: bold;
    }

    p {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .net {
        background-color: #5271ff !important;
        display: flex;
        align-items: center;
        padding: 20px 0px;
        border-radius: 15px !important;
        margin: 15px 0 25px 0;
    }

    .spacer {
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        height: 70px;
    }

    .net-half {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        color: #fff;
    }

    .net-half i {
        color: #fff;
        font-size: 2em;
    }

    .net-half h1 {
        font-size: 1.5em;
        margin-top: 5px;
    }

    .net-half p {
        font-size: 0.8em;
        margin: 0;
        font-weight: 100;
    }

    .card {
        background-color: #fcfcfc !important;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 15px !important;
        margin: 5px 0 25px 0;
    }

    .card-left i {
        color: #5271ff;
        font-size: 3.5em;
        margin-right: 10px;
    }

    .card-right {
        width: 100%;
    }

    .card-end i {
        color: #5271ff;
        font-size: 2em;
        margin-right: 10px;
    }

    .card-right h3 {
        font-size: 1em;
    }

    .card-right p {
        font-size: 0.8em;
        margin: 0;
        font-weight: 300;
    }

    h4 {
        margin: 5px;
    }

    .title {
        font-size: 20px !important;
        /* margin-left: 20px; */
        color: #5271ff;
    }

    .graph-card {
        background-color: #fcfcfc !important;
        display: flex;
        align-items: center;
        padding: 10px 10px;
        border-radius: 15px !important;
        margin: 5px 0 25px 0;
    }

    .no-data {
        text-align: center;
        font-size: 0.8em;
        font-weight: bold;
        margin: 20px;
    }

    .showing {
        font-weight: bold;
        font-size: 0.9em;
        margin: 5px 0 20px 0;
    }
</style>