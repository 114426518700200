<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>


                <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>
                <v-toolbar-title class="title">Personal Profile</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                    <v-icon>mdi-message-text</v-icon>
                </v-btn> -->


        </v-app-bar>

        <div class="main">

            <!-- <h4>Your Personal Profile</h4> -->
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="name" :rules="nameRules" label="Name" prepend-icon="mdi-account" required>
                </v-text-field>

                <v-text-field v-model="surname" :rules="nameRules" label="Surname" prepend-icon="mdi-account" required>
                </v-text-field>

                <v-text-field v-model="email" :rules="emailRules" label="E-mail" prepend-icon="mdi-email" required>
                </v-text-field>

                <v-text-field v-model="phoneNumber" :rules="phoneNumberRules" label="Phone Number"
                    prepend-icon="mdi-phone" required>
                </v-text-field>

                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="Birthdate" prepend-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01" @change="save"></v-date-picker>
                </v-menu>

                <v-select v-model="gender" :items="genderItems" label="Gender" prepend-icon="mdi-account" required>
                </v-select>

                <v-select v-model="race" :items="raceItems" label="Race" prepend-icon="mdi-account" required>
                </v-select>

                <v-select v-model="nationality" :items="nationalityItems" label="Nationality" prepend-icon="mdi-flag"
                    required>
                </v-select>

                <v-text-field v-model="identification" :rules="nameRules" label="ID/Passport"
                    prepend-icon="mdi-card-account-details-outline" required>
                </v-text-field>

                <v-text-field v-model="jobTitle" :rules="nameRules" label="Job Title"
                    prepend-icon="mdi-briefcase-account" required>
                </v-text-field>

                <v-btn class="button" block :loading="loader" @click="PatchUser(), loader = true">
                    Update
                </v-btn>

            </v-form>
        </div>
    </v-container>
</template>

<script>
    // import {
    //     Auth
    // } from "aws-amplify";
    import {
        API
    } from "aws-amplify";

    export default {
        data: () => ({
            loader: false,
            valid: true,
            name: '',
            surname: '',
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            phoneNumber: '',
            phoneNumberRules: [
                v => !!v || 'Phone number is required'
                // v => (v && v.length <= 20) || 'Name must be less than 10 characters',
            ],

            activePicker: null,
            date: null,
            menu: false,

            gender: '',
            genderItems: [
                'Male',
                'Female',
                'Other',
                'Prefer not to say',
            ],

            race: '',
            raceItems: [
                'Black African',
                'Colored',
                'White',
                'Indian/Asian',
                'Prefer not to say',
            ],

            nationality: 'Select',
            nationalityItems: [
                'South African',
                'Pakistan',
                'UK',
                'Zimbabwean',
            ],

            identification: '',

            jobTitle: ''

        }),

        methods: {
            save(date) {
                this.$refs.menu.save(date)
            },

            Back() {
                this.$router.push({
                    name: 'Settings'
                }).catch(() => {})
            },

            async GetUser() {
                const apiName = "MoyaAPI";
                const apiPath = "/user";
                try {
                    const response = await API.get(apiName, apiPath);
                    console.log(response);
                    if (!response.response.Item.Gender) {
                        this.name = response.response.Item.Name.S;
                        this.surname = response.response.Item.Surname.S;
                        this.date = response.response.Item.DOB.S;
                        this.email = response.response.Item.Email.S;
                        this.phoneNumber = response.response.Item.Cell.N;
                    } else {
                        this.name = response.response.Item.Name.S;
                        this.surname = response.response.Item.Surname.S;
                        this.email = response.response.Item.Email.S;
                        this.phoneNumber = response.response.Item.Cell.N;
                        this.date = response.response.Item.DOB.S;
                        this.gender = response.response.Item.Gender.S;
                        this.race = response.response.Item.Race.S;
                        this.nationality = response.response.Item.Nationality.S;
                        this.identification = response.response.Item.Identification.S;
                        this.jobTitle = response.response.Item.Job_Title.S;
                    }
                } catch (error) {
                    console.log(error.response);
                }
            },

            async PatchUser() {
                const apiName = "MoyaAPI";
                const apiPath = "/user";
                const apiRequest = {
                    body: {
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        cell: this.phoneNumber,
                        birthDate: this.date,
                        gender: this.gender,
                        race: this.race,
                        nationality: this.nationality,
                        identification: this.identification,
                        jobTitle: this.jobTitle,
                    },
                };
                try {
                    const response = await API.patch(apiName, apiPath, apiRequest);
                    console.log(response);
                    this.loader = false
                } catch (error) {
                    console.log(error.response);
                    this.loader = false
                }
            },
        },

        created() {
            this.GetUser();
            document.title = 'Moya | Personal Profile'
        }
    }
</script>

<style scoped>
    .container {
        padding: 70px 30px 60px!important;
    }

    h4 {
        margin-bottom: 10px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

</style>