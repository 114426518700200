<template>
  <div class="main">

    <v-icon v-if="onboarding == 0" class="back" @click="$router.go(-1)">chevron_left</v-icon>
    <v-icon v-if="onboarding > 0" class="back" @click="prev()">chevron_left</v-icon>

    <v-card class="container" flat tile>

      <div>
        <v-window show-arrows v-model="onboarding">

          <template  v-slot:prev="{ on, attrs }">
            <v-icon v-show="false" color="#5271ff" v-bind="attrs" v-on="on">chevron_left</v-icon>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-icon v-show="false" color="#5271ff" v-bind="attrs" v-on="on">chevron_right</v-icon>
          </template>

          <v-window-item>
            <v-card color="transparent">
              <v-row align="center" justify="center">
                <img src="../assets/Make sense of the numbers.png" alt="">
                <h3 class="text-center">Make Sense of Numbers</h3>
                <v-card-text class="text-center">
                  Link you bank accounts to manage your personal and business finances in one place.
                </v-card-text>
              </v-row>
            </v-card>
          </v-window-item>

          <v-window-item>
            <v-card color="transparent">
              <v-row align="center" justify="center">
                <img src="../assets/Organise expenses.png" alt="">
                <h3 class="text-center">Organise your expenses</h3>
                <v-card-text class="text-center">
                  Easily and efficiently separate your personal and business expenses.
                </v-card-text>
              </v-row>
            </v-card>
          </v-window-item>

          <v-window-item>
            <v-card color="transparent">
              <v-row align="center" justify="center">
                <img src="../assets/Total visibility.png" alt="">
                <h3 class="text-center">Get total visibility</h3>
                <v-card-text class="text-center">
                  See an overview of your spending and income to make better decisions.
                </v-card-text>
              </v-row>
            </v-card>
          </v-window-item>

          <v-window-item>
            <v-card color="transparent">
              <v-row align="center" justify="center">
                <img src="../assets/Tailored assistance.png" alt="">
                <h3 class="text-center">Tailored assistance</h3>
                <v-card-text class="text-center">
                  Talk to our accountant partner for tax needs and compliance.
                </v-card-text>
              </v-row>
            </v-card>
          </v-window-item>

        </v-window>

        <v-card-actions class="justify-center">
          <v-item-group v-model="onboarding" class="text-center" mandatory>
            <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }">
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon small color="#5271ff">mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-card-actions>
      </div>
    </v-card>

    <v-btn v-if="onboarding == 3" class="button" block @click="Signup()">Create Account</v-btn>
    <v-btn v-if="onboarding < 3" class="button" block @click="next()">Next</v-btn>
  </div>
</template>

<script>
  export default {
    data: () => ({
      length: 4,
      onboarding: 0,
    }),

    methods: {

      Signup() {
        this.$router.push({
          name: 'Signup'
        }).catch(() => {})
      },

      next() {
        this.onboarding = this.onboarding + 1 === this.length ?
          0 :
          this.onboarding + 1
      },
      prev() {
        this.onboarding = this.onboarding - 1 < 0 ?
          this.length - 1 :
          this.onboarding - 1
      },
    },
  }
</script>

<style scoped>
  .main {
    display: flex;
    align-content: center;
    justify-items: center;
    flex-direction: column;
    height: 100%;
    padding: 30px;
  }

  .container {
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 80%;
  }

  .back {
    color: #5271ff !important;
    font-weight: bold;
    font-size: 3em !important;
    text-align: left !important;
    position: absolute !important;
    left: 20px;
    top: 20px;
    z-index: 200;
  }

  .button {
    align-items: center;
    border-radius: 20px;
    display: inline-flex;
    flex: 0 0 auto;
    text-transform: none;
    border: none;
    background-color: #5271ff !important;
    color: #fff;
    height: 40px !important;
    margin-bottom: 10px;
    box-shadow: none !important;
    border: none !important;
    margin-top: 30px;
  }
</style>