<template>
    <v-container fluid>

        <!-- <v-app-bar flat color="#fff" fixed>


            <v-spacer></v-spacer>

            <v-btn icon>
                <v-icon class="account">mdi-message-text</v-icon>
            </v-btn>



        </v-app-bar> -->

        <v-main>
            <v-container fluid>


                <router-view></router-view>
            </v-container>
        </v-main>

        <v-bottom-navigation v-model="value" fixed grow color="#5271ff">

            <v-btn @click="Overview()" value="overview">
                <span>Overview</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>

            <v-btn @click="Transactions()" value="transactions">
                <span>Transactions</span>
                <v-icon>mdi-view-list</v-icon>
            </v-btn>

            <v-btn @click="Analytics()" value="analytics">
                <span>Analytics</span>
                <v-icon>mdi-chart-pie</v-icon>
            </v-btn>

            <v-btn @click="Profile()" value="profile">
                <span>Profile</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>

            <!-- <v-btn @click="Test()" value="refresh">
                <v-icon>mdi-refresh</v-icon>
            </v-btn> -->

            <!-- <v-btn @click="RefreshToken()" value="settings">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn @click="RefreshToken()" value="settings">
                <v-icon>mdi-plus</v-icon>
            </v-btn> -->
        </v-bottom-navigation>



    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";

    import {
        API
    } from "aws-amplify";

    import CryptoJS from "crypto-js";

    export default {
        data: () => ({

            value: 'overview',
            initials: '',
            fullName: '',
            email: '',
            loader: false,
        }),

        methods: {


            async SignOut() {

                try {
                    await Auth.signOut({
                        global: true
                    });

                    this.loader = false,

                        this.$router.push({
                            name: 'Splash'
                        }).catch(() => {})

                } catch (error) {

                    if (error.code == "NotAuthorizedException") {

                        this.$router.push({
                            name: 'Splash'
                        }).catch(() => {})

                        this.loader = false

                    }

                    this.loader = false
                }
            },

            Transactions() {
                this.$router.push({
                    name: 'Transactions'
                }).catch(() => {})
            },

            Overview() {
                this.$router.push({
                    name: 'Overview'
                }).catch(() => {})
            },

            Analytics() {
                this.$router.push({
                    name: 'Analytics'
                }).catch(() => {})
            },

            Add() {
                this.$router.push({
                    name: 'Add'
                }).catch(() => {})
            },

            Invoice() {
                this.$router.push({
                    name: 'Invoices'
                }).catch(() => {})
            },

            Profile() {
                this.$router.push({
                    name: 'Profile'
                }).catch(() => {})
            },

            async RefreshToken() {
                console.log("refreshingtoken");
                const user = await Auth.currentAuthenticatedUser({
                    bypassCache: true
                });
                const token = this.DecryptData(
                    user.attributes["custom:refresh_token"],
                    user.username
                );
                const apiName = "MoyaAPI";
                const apiPath = "/token";
                const apiRequest = {
                    queryStringParameters: {
                        type: "refresh_token",
                        refreshToken: token,
                        env: process.env.NODE_ENV
                    },
                };
                try {
                    const response = await API.get(apiName, apiPath, apiRequest);

                    const user = await Auth.currentAuthenticatedUser();
                    const access_token = this.EncryptData(
                        response.tokenData.access_token,
                        user.username
                    );
                    const refresh_token = this.EncryptData(
                        response.tokenData.refresh_token,
                        user.username
                    );
                    await Auth.updateUserAttributes(user, {
                        "custom:access_token": access_token,
                        "custom:refresh_token": refresh_token,
                    });
                } catch (error) {
                    console.log(error);
                    if (error.response.status == 400) {
                        console.log(error.response.data.error);
                    } else {
                        console.log(error);
                    }
                }
            },

            async InitialStitchQuery() {
                try {
                    const user = await Auth.currentAuthenticatedUser({
                        bypassCache: true
                    });
                    const token = this.DecryptData(
                        user.attributes["custom:access_token"],
                        user.username
                    );
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${token}`);
                    var graphql = JSON.stringify({
                        query: ` query InitialQuery {
                        user {
                            __typename
                            bankAccounts {
                            accountNumber
                            accountType
                            availableBalance
                            accountHolder {
                                ... on Business {
                                email
                                name
                                registration {
                                    number
                                }
                                businessAddress {
                                    country
                                    postalCode
                                    streetAddress
                                }
                                }
                                ... on Individual {
                                gender
                                fullName
                                email
                                dateOfBirth
                                identifyingDocument {
                                    ... on IdentityDocument {
                                    __typename
                                    }
                                }
                                }
                            }
                            currency
                            branchCode
                            currentBalance
                            bankId
                            }
                        }
                    }`,
                        variables: {},
                    });
                    var requestOptions = {
                        credentials: "include",
                        method: "POST",
                        headers: myHeaders,
                        body: graphql,
                        mode: "cors",
                    };
                    fetch("https://api.stitch.money/graphql", requestOptions).then(
                        (response) => {
                            const json = response.json();
                            Promise.resolve(json).then((stitchData) => {
                                // console.log(stitchData);

                                if (!stitchData.errors && stitchData.data.user) {
                                    // console.info('bank data');
                                    // console.log(data.user.bankAccounts[0]);
                                    // console.log(stitchData.data.user);
                                } else if (stitchData.errors[0].message ==
                                    "UNAUTHENTICATED: Token is expired or malformed") {
                                    console.info("UNAUTHENTICATED: Token is expired or malformed");
                                    // console.log(stitchData.errors[0].message);
                                    this.RefreshToken();
                                } else if (stitchData.errors[0].message == "Reauthorization is required") {
                                    console.info("Reauthorization is required");
                                    window.location.replace(this.buildAuthorizationUrl(stitchData.errors[0]
                                        .extensions
                                        .userInteractionUrl))
                                }
                                
                            });
                        }
                    );
                } catch (error) {
                    console.log(error);
                }
            },

            buildAuthorizationUrl(url) {
                const search = {
                    redirect_uri: 'https://localhost:8080/bank-success'
                };
                const searchString = Object.entries(search)
                    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
                    .join("&");
                // console.log(url + searchString)

                return url + '?' + searchString;
            },

            DecryptData(token, secret) {
                // decrypt the data and convert to string
                const decryptData = CryptoJS.AES.decrypt(token, secret).toString(
                    CryptoJS.enc.Utf8
                );
                // console.log(decryptData)
                return decryptData;
            },

            EncryptData(token, secret) {
                if (token.length) {
                    // hash the name with any algorithm
                    const data = CryptoJS.AES.encrypt(token, secret).toString();
                    return data;
                }
            },

            Test() {
                window.location.replace(this.buildAuthorizationUrl(
                    "https://secure.stitch.money/connect/authorization/654cafeb-aadf-441d-801c-ddcd0b670f1a"));
            },

        },

        created() {
            // this.RefreshToken()
            this.InitialStitchQuery()
            console.log(process.env.NODE_ENV)
        },
    }
</script>

<style scoped>
    .container {
        padding: 0px !important;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        box-shadow: none !important;
        border: none !important;
    }

    .account {
        /* color: #000 !important; */
        /* font-size: 2em !important; */
    }

    .v-item-group.v-bottom-navigation .v-btn {
        min-width: 64px;
    }
</style>