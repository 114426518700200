<template>
    <v-container fluid>

        <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackColor" absolute top>
            {{ snackText }}
        </v-snackbar>

        <v-icon @click="Splash()">chevron_left</v-icon>

        <v-form ref="form" v-model="valid">
            <h1>Sign In</h1>

            <v-text-field v-model="email" :rules="emailRules" label="E-mail" autocomplete="username" required>
            </v-text-field>

            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                label="Password" autocomplete="password" hint="At least 8 characters" @click:append="show1 = !show1">
            </v-text-field>

            <v-btn block :disabled="!valid || loader" :loading="loader" @click="Signin(), loader = true">
                Sign In
            </v-btn>

            <p class="forgot" @click="Forgot()">Forgot Password</p>
        </v-form>
    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";
    // import { API } from "aws-amplify";

    export default {
        data: () => ({
            valid: true,

            snackbar: false,
            snackText: '',
            snackColor: 'red',
            timeout: 2000,

            loader: false,

            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            checkbox: false,

            show1: false,
            password: '',

            show2: false,
            password2: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
        }),

        methods: {
            validate() {
                this.$refs.form.validate()
            },

            Splash() {
                this.$router.push({
                    name: 'Splash'
                }).catch(() => {})
            },

            Forgot() {
                this.$router.push({
                    name: 'ForgotPassword'
                }).catch(() => {})
            },

            async Signin() {
                try {
                    const user = await Auth.signIn(this.email.toLowerCase().replace(/\s/g, ''), this.password);

                    if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
                        console.log("Requires MFA token");
                        // You need to get the code from the UI inputs
                        // and then trigger the following function with a button click
                        const code = this.code;
                        // If MFA is enabled, sign-in should be confirmed with the confirmation code
                        const loggedUser = await Auth.confirmSignIn(
                            user, // Return object from Auth.signIn()
                            code, // Confirmation code
                            "SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                        );
                        console.log(loggedUser);
                    }
                    //else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    //   const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                    //   // You need to get the new password and required attributes from the UI inputs
                    //   // and then trigger the following function with a button click
                    //   // For example, the email and phone_number are required attributes
                    //   const { username, email, phone_number } = getInfoFromUserInput();
                    //   const loggedUser = await Auth.completeNewPassword(
                    //     user, // the Cognito User Object
                    //     newPassword, // the new password
                    //     // OPTIONAL, the required attributes
                    //     {
                    //       email,
                    //       phone_number,
                    //     }
                    //   );
                    // }
                    else if (user.challengeName === "MFA_SETUP") {
                        console.log("Requires MFA setup");
                        // This happens when the MFA method is TOTP
                        // The user needs to setup the TOTP before using it
                        // More info please check the Enabling MFA part
                        Auth.setupTOTP(user).then((code) => {
                            // You can directly display the `code` to the user or convert it to a QR code to be scanned.
                            const str =
                                "otpauth://totp/AWSCognito:" +
                                this.email.toLowerCase().replace(/\s/g, '') +
                                "?secret=" +
                                code +
                                "&issuer=Moya";
                            this.value = str;
                            this.size = 300;
                        });
                    } else {
                        console.log("Successful login");
                        // The user directly signs in
                        // console.log(user);

                        if (user.attributes['custom:form_profile'] == '1') {
                            if (user.attributes['custom:form_business'] == '1') {
                                if (user.attributes['custom:form_bank'] == '1') {
                                    this.$router.push({
                                        name: 'Overview'
                                    }).catch(() => {})
                                } else {
                                    this.$router.push({
                                        name: 'BankWelcome'
                                    }).catch(() => {})
                                }
                            } else {

                                this.$router.push({
                                    name: 'BusinessWelcome'
                                }).catch(() => {})

                            }
                        } else {
                            this.$router.push({
                                name: 'Welcome'
                            }).catch(() => {})
                        }



                    }
                } catch (err) {

                    this.loader = false;

                    if (err.code === "UserNotConfirmedException") {
                        this.$router.push({
                            name: 'ConfirmEmailLogin'
                        }).catch(() => {})
                    } else if (err.code === "PasswordResetRequiredException") {
                        // The error happens when the password is reset in the Cognito console
                        // In this case you need to call forgotPassword to reset the password
                        // Please check the Forgot Password part.
                    } else if (err.code === "NotAuthorizedException") {
                        // The error happens when the incorrect password is provided
                        // console.log()
                        this.snackbar = true;
                        this.snackText = err.message;
                    } else if (err.code === "UserNotFoundException") {
                        // The error happens when the supplied username/email does not exist in the Cognito user pool
                    } else {
                        console.log(err);
                    }
                }
            },

        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 20px 10px !important;
    }

    .v-icon {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 3em !important;
        text-align: left !important;
    }

    .v-form {
        margin: 20px;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .forgot {
        text-align: center;
        font-weight: bold;
        font-size: 0.9em;
        text-decoration: underline;
        color: #5271ff;
    }
</style>