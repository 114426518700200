<template>

    <v-container fluid>

        <div class="overlay" v-if="overlay">
            <img src="../../assets/loading.svg" alt="">
            <v-progress-linear color="#5271ff" indeterminate rounded height="5" class="ma-2 mt-4"></v-progress-linear>
            <p>Please hang tight while we import transactions from your bank account.</p>
            <p>This may take a sometime.</p>
        </div>


        <v-app-bar flat color="#fff" fixed>

            <v-toolbar fixed flat>
                <!-- <v-icon class="back" @click="Back()">chevron_left</v-icon> -->
                <v-toolbar-title class="title">Overview</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon @click="RefreshData()">
                    <v-icon class="account">mdi-refresh</v-icon>
                </v-btn> -->

                <v-btn icon @click="Notifications()">
                    <v-badge color="#DD1155" dot :value="notification">
                        <v-icon class="account">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>


            </v-toolbar>

        </v-app-bar>

        <v-skeleton-loader v-if="loader" type="article, list-item, image, image"></v-skeleton-loader>

        <span v-show="!loader">
            <h4>Available Balance</h4>
            <h1>R{{accountBalance}}</h1>


            <h4 class="mt-4 mb-0">Your Overview for {{monthName}}</h4>
            <v-card class="net">

                <div class="net-half">
                    <v-icon :color="''">mdi-arrow-down-thin-circle-outline</v-icon>
                    <h1>R{{income}}</h1>
                    <p>Income</p>
                </div>

                <div class="spacer"></div>

                <div class="net-half">
                    <v-icon :color="''">mdi-arrow-up-thin-circle-outline</v-icon>
                    <h1 :color="'#DD1155'">R{{expense}}</h1>
                    <p>Expenses</p>
                </div>

            </v-card>

            <h4>Your cards</h4>


            <div class="text-center">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">

                        <v-card class="card" v-bind="attrs" v-on="on">

                            <div class="card-left">
                                <v-icon>mdi-credit-card</v-icon>
                            </div>

                            <div class="card-right">
                                <h3>{{bankAccountName}}</h3>
                                <p>{{bankId}}</p>
                            </div>

                            <div class="card-end">
                                <v-icon>mdi-chevron-right</v-icon>
                            </div>

                        </v-card>

                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in items" :key="index" @click="UpdateOverview(index);">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <h4 class="mt-4 mb-0">Overall (Last 7 days)</h4>

            <v-card class="graph-card">
                <VueApexCharts ref="chart" type="area" height="350" :options="chartOptions" :series="series">
                </VueApexCharts>
            </v-card>

        </span>
    </v-container>

</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    import {
        Auth
    } from "aws-amplify";

    import {
        API
    } from "aws-amplify";

    import CryptoJS from "crypto-js";

    // import {
    //     GraphQLClient
    // } from "graphql-request";

    export default {

        components: {
            VueApexCharts
        },

        data: () => ({

            notification: null,
            businessID: null,
            bankAccountName: "",
            bankId: "",
            accountBalance: "",
            loader: true,
            chartWidth: 350,
            monthName: null,
            refresh: false,
            graph: false,
            accountIndex: 0,

            overlay: true,

            bankData: null,
            transactionData: null,
            items: [],

            // drawChart = false,

            incomeAmounts: [],
            expenseAmounts: [],

            income: 561,
            expense: 200,

            series: [{
                    name: "Income",
                    data: [12, 11, 14, 50, 17, 13, 13]
                },
                {
                    name: "Expense",
                    data: [46, 11, 14, 6, 17, 36, 13]
                }

            ],
            chartOptions: {
                chart: {
                    height: 350,
                    width: 3000,
                    type: 'area',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        autoScaleYaxis: true
                    }

                },
                colors: ['#52ffa0', '#ff5252'],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: '`',
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['transparent',
                            'transparent'
                        ], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    // type: 'datetime',
                    categories: [new Date().getDate() - 6, new Date().getDate() - 5,
                        new Date().getDate() - 4, new Date().getDate() - 3, new Date().getDate() - 2,
                        new Date().getDate() - 1, new Date().getDate()
                    ],
                    labels: {
                        hideOverlappingLabels: true,
                        trim: true
                    },
                    // min: 1,
                    // max: 10,
                    // range: 7
                    title: {
                        text: 'Day of Month'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Amount (R)'
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            },

        }),

        created() {

            document.title = 'Moya | Overview'

            // console.log('created')
            // this.GetBusinessID();
            // console.log(this.TransactionsData)
            // console.log(this.BankData.Items)

            this.notification = this.Notification;


            if (this.TransactionsData != null && this.BankData != null && this.BusinessID != null) {

                console.log("from store")

                this.transactionData = this.TransactionsData;
                this.bankData = this.BankData;
                this.businessID = this.BusinessID;
                this.accountIndex = this.SelectedAccount;

                this.ProcessBankData(this.bankData.Items)

            } else {

                console.log("from api")

                this.GetBusinessID();
            }

            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
                "October", "November", "December"
            ];
            let d = new Date();
            this.monthName = months[d.getMonth()]; // "July" (or current month)
        },

        computed: {
            TransactionsData() {
                return this.$store.getters.TransactionsData
            },

            BankData() {
                return this.$store.getters.BankData
            },

            BusinessID() {
                return this.$store.getters.BusinessID
            },

            SelectedAccount() {
                return this.$store.getters.SelectedAccount
            },

            Notification() {
                return this.$store.getters.Notification
            },
        },

        methods: {
            Notifications() {

                this.$store.dispatch('Notification', false);

                this.$router.push({
                    name: 'Notifications'
                }).catch(() => {})
            },

            RefreshData() {
                this.refresh = true
                this.loader = true
                this.overlay = true


                this.$store.dispatch('TransactionsData', null);
                this.$store.dispatch('BankData', null);

                this.OverviewStitchQuery()
            },

            async GetBusinessID() {

                this.loader = true;
                this.overlay = true

                const apiName = "MoyaAPI";
                const apiPath = "/user";
                try {
                    const response = await API.get(apiName, apiPath);
                    // console.log(response)
                    this.businessID = response.response.Item.Business_Ids.SS[0];
                    this.GetUserInfo();

                } catch (error) {
                    console.log(error.response);
                }
            },

            async GetUserInfo() {
                await Auth.currentAuthenticatedUser({
                    bypassCache: true,
                }).then(user => {

                    if (user.attributes['custom:login'] == "0") {

                        console.log("first time")
                        this.OverviewStitchQuery()

                    } else {
                        console.log("not first time");
                        this.GetStoredData()


                    }

                }).catch(error => {
                    if (error === "The user is not authenticated") {
                        this.$router.push({
                            name: 'Splash'
                        }).catch(() => {})
                    } else {
                        console.log(error);
                    }
                });

            },

            async OverviewStitchQuery() {

                try {
                    const user = await Auth.currentAuthenticatedUser({
                        bypassCache: true
                    });
                    const token = this.DecryptData(
                        user.attributes["custom:access_token"],
                        user.username
                    );
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${token}`);
                    var graphql = JSON.stringify({
                        query: ` query Overview {
                        user {
                            __typename
                            bankAccounts {
                                name
                                availableBalance
                                bankId
                                currentBalance
                                transactions {
                                    edges {
                                        node {
                                            id
                                            amount
                                            date
                                            description
                                            reference
                                        }
                                    }
                                }
                            }
                        }
                    }`,
                        variables: {},
                    });
                    var requestOptions = {
                        credentials: "include",
                        method: "POST",
                        headers: myHeaders,
                        body: graphql,
                        mode: "cors",
                    };
                    fetch("https://api.stitch.money/graphql", requestOptions).then(
                        (response) => {
                            const json = response.json();
                            Promise.resolve(json).then((stitchData) => {
                                // console.log(stitchData);

                                if (!stitchData.errors && stitchData.data.user) {
                                    this.StoreTransactions(stitchData.data.user)

                                } else if (stitchData.errors[0].message ==
                                    "Resource unavailable: bank_field_temporarily_unresponsive") {
                                    this.StoreTransactions(stitchData.data.user)

                                } else if (stitchData.errors[0].message ==
                                    "UNAUTHENTICATED: Token is expired or malformed") {
                                    console.info("UNAUTHENTICATED: Token is expired or malformed");
                                    this.RefreshToken();
                                } else if (stitchData.errors[0].message ==
                                    "Reauthorization is required") {
                                    console.info("Reauthorization is required");
                                    window.location.replace(this.buildAuthorizationUrl(stitchData
                                        .errors[0]
                                        .extensions
                                        .userInteractionUrl))
                                }

                            });
                        }
                    );
                } catch (error) {
                    console.log(error);
                }
            },

            async StoreTransactions(data) {
                let bankAccounts = [];
                let transactions = [];

                for (let i = 0; i < data.bankAccounts.length; i++) {
                    const bankAccount = data.bankAccounts[i];
                    bankAccounts.push({
                        availableBalance: bankAccount.availableBalance.quantity,
                        currentBalance: bankAccount.currentBalance.quantity,
                        accountName: bankAccount.name,
                        bankId: bankAccount.bankId
                    });

                    if (this.refresh) {

                        for (let b = 0; b < this.bankData.Items.length; b++) {
                            const bankAccount = this.bankData.Items[b];
                            if (bankAccounts[i].accountName == bankAccount.Account_Name.S) {
                                bankAccounts[i]["id"] = bankAccount.user_id.S;
                            }

                        }

                    }

                    if (bankAccount.transactions) {

                        for (let j = 0; j < bankAccount.transactions.edges.length; j++) {
                            const transaction = bankAccount.transactions.edges[j].node;

                            if (transaction.reference == null) {
                                transaction['reference'] = ''
                            }

                            transactions.push({
                                id: transaction.id,
                                reference: transaction.reference,
                                description: transaction.description,
                                amount: transaction.amount.quantity,
                                accountName: bankAccount.name,
                                date: transaction.date,
                                icon: "mdi-plus-box"
                            });
                        }

                    } else {
                        // this.loader = false
                    }
                }

                const apiName = "MoyaAPI";
                const apiPath = "/user_transaction";
                const apiRequest = {
                    body: {
                        businessId: this.businessID,
                        transactions: transactions,
                        bankAccounts: bankAccounts,
                    },
                };
                try {

                    let response = null;
                    if (this.refresh) {
                        response = await API.patch(apiName, apiPath, apiRequest);
                        console.log(apiRequest);
                    } else {
                        response = await API.post(apiName, apiPath, apiRequest);
                    }

                    console.log(response);

                    const user = await Auth.currentAuthenticatedUser();
                    await Auth.updateUserAttributes(user, {
                        "custom:login": "1",
                    });

                    this.GetStoredData()

                } catch (error) {
                    console.log(error.response);
                    this.loader = false;
                    this.overlay = false;
                }
            },

            async GetStoredData() {

                const apiName = "MoyaAPI";
                const apiPath = "/user_transaction";
                const apiRequest = {
                    queryStringParameters: {
                        businessId: this.businessID,
                    },
                };
                try {
                    const response = await API.get(apiName, apiPath, apiRequest);
                    console.log(response);

                    this.transactionData = response.responseTransactions;
                    this.bankData = response.responseBank;

                    this.$store.dispatch('TransactionsData', this.transactionData);
                    this.$store.dispatch('BankData', this.bankData);
                    this.$store.dispatch('BusinessID', this.businessID);

                    this.ProcessBankData(response.responseBank.Items)

                } catch (error) {
                    console.log(error);
                }
            },

            ProcessBankData(data) {

                this.graph = false;
                this.items = [];

                // console.log(data)

                for (let i = 0; i < data.length; i++) {
                    const element = data[i].Account_Name.S;

                    let accountDetails = {
                        name: element
                    }

                    this.items.push(accountDetails)
                }

                this.UpdateOverview(this.accountIndex)
            },

            async UpdateOverview(account) {

                this.$store.dispatch('SelectedAccount', account);

                try {

                    // console.log(this.transactionData)

                    await this.transactionData;

                    this.income = 0;
                    this.expense = 0;

                    this.incomeAmounts = [];
                    this.expenseAmounts = [];

                    this.bankAccountName = this.bankData.Items[account].Account_Name.S;
                    this.bankId = this.bankData.Items[account].Bank_Id.S;
                    this.accountBalance = this.bankData.Items[account].Available_Balance.S;

                    this.loader = false
                    this.overlay = false

                    let transactions = [];

                    for (let a = 0; a < this.transactionData.Items.length; a++) {
                        const transaction = this.transactionData.Items[a];

                        if (transaction.Account_Name.S == this.bankAccountName) {
                            if (new Date(transaction.Transaction_Date.S).getMonth() == new Date().getMonth())
                                transactions.push(transaction)
                        }
                    }

                    // console.log(transactions)

                    let days = new Date().getDate()

                    for (let i = 0; i < days; i++) {

                        this.incomeAmounts[i] = 0;
                        this.expenseAmounts[i] = 0;

                        for (let j = 0; j < transactions.length; j++) {
                            const element = transactions[j];
                            const day = new Date(element.Transaction_Date.S).getDate();

                            if (day == i + 1 && day > days - 7 && day <= days) {



                                if (parseInt(element.Amount.S) > 0) {
                                    this.incomeAmounts[i] += Math.abs(parseInt(element.Amount.S))
                                } else {
                                    this.expenseAmounts[i] += Math.abs(parseInt(element.Amount.S))
                                }
                            }

                            if (day == i + 1) {



                                if (parseInt(element.Amount.S) > 0) {
                                    this.income += Math.abs(parseInt(element.Amount.S))
                                } else {
                                    this.expense += Math.abs(parseInt(element.Amount.S))
                                }
                            }
                        }
                    }

                    this.incomeAmounts.splice(0, days - 7)
                    this.expenseAmounts.splice(0, days - 7);

                    // this.series[0] = this.incomeAmounts

                    // console.log(this.incomeAmounts)
                    // console.log(this.expenseAmounts)

                    this.series[0].data = this.incomeAmounts;
                    this.series[1].data = this.expenseAmounts;
                    // this.series[1].data = [46, 11, 14, 6, 17, 36, 13];

                    // console.log(this.series)

                    // this.graph = true;

                    this.$refs.chart.updateSeries([{
                            name: "Income",
                            data: this.incomeAmounts
                        },
                        {
                            name: "Expense",
                            data: this.expenseAmounts
                        }

                    ])

                    this.loader = false
                    this.overlay = false

                } catch (error) {
                    console.log(error);
                }

            },

            async RefreshToken() {
                console.log("refreshingtoken");
                const user = await Auth.currentAuthenticatedUser({
                    bypassCache: true
                });
                const token = this.DecryptData(
                    user.attributes["custom:refresh_token"],
                    user.username
                );
                const apiName = "MoyaAPI";
                const apiPath = "/token";
                const apiRequest = {
                    queryStringParameters: {
                        type: "refresh_token",
                        refreshToken: token,
                        env: process.env.NODE_ENV
                    },
                };
                try {
                    const response = await API.get(apiName, apiPath, apiRequest);

                    const user = await Auth.currentAuthenticatedUser();
                    const access_token = this.EncryptData(
                        response.tokenData.access_token,
                        user.username
                    );
                    const refresh_token = this.EncryptData(
                        response.tokenData.refresh_token,
                        user.username
                    );
                    await Auth.updateUserAttributes(user, {
                        "custom:access_token": access_token,
                        "custom:refresh_token": refresh_token,
                    });

                    location.reload();
                } catch (error) {
                    console.log(error);
                    if (error.response.status == 400) {
                        console.log(error.response.data.error);
                        // location.reload();
                    } else {
                        console.log(error);
                    }
                }
            },

            buildAuthorizationUrl(url) {
                const search = {
                    redirect_uri: 'https://localhost:8080/bank-success'
                };
                const searchString = Object.entries(search)
                    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
                    .join("&");
                // console.log(url + searchString)

                return url + '?' + searchString;
            },

            DecryptData(token, secret) {
                // decrypt the data and convert to string
                const decryptData = CryptoJS.AES.decrypt(token, secret).toString(
                    CryptoJS.enc.Utf8
                );
                // console.log(decryptData)
                return decryptData;
            },

            EncryptData(token, secret) {
                if (token.length) {
                    // hash the name with any algorithm
                    const data = CryptoJS.AES.encrypt(token, secret).toString();
                    return data;
                }
            },
        },



    }
</script>

<style scoped>
    .container {
        padding: 60px 30px !important;
    }

    .overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
        z-index: 500;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 75px;
    }

    .overlay img {
        width: 100%
    }

    .overlay p {
        text-align: center;
    }

    h1 {
        font-weight: bold;
    }

    p {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .net {
        background-color: #5271ff !important;
        display: flex;
        align-items: center;
        padding: 20px 0px;
        border-radius: 15px !important;
        margin: 5px 0 25px 0;
    }

    .spacer {
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        height: 70px;
    }

    .net-half {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        color: #fff;
    }

    .net-half i {
        color: #fff;
        font-size: 2em;
    }

    .net-half h1 {
        font-size: 1.5em;
        margin-top: 5px;
    }

    .net-half p {
        font-size: 0.8em;
        margin: 0;
        font-weight: 100;
    }

    .card {
        background-color: #fcfcfc !important;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 15px !important;
        margin: 5px 0 25px 0;
    }

    .card-left i {
        color: #5271ff;
        font-size: 3.5em;
        margin-right: 10px;
    }

    .card-right {
        width: 100%;
    }

    .card-end i {
        color: #5271ff;
        font-size: 2em;
        margin-right: 10px;
    }

    .card-right h3 {
        font-size: 1em;
        text-align: left;
    }

    .card-right p {
        font-size: 0.8em;
        margin: 0;
        font-weight: 300;
        text-align: left;
    }

    h4 {
        /* margin-bottom: 5px; */
    }

    .title {
        font-size: 20px !important;
        /* margin-left: 20px; */
        color: #5271ff;
    }

    .apexcharts-title-text {
        color: #ff5252 !important;
    }

    .graph-card {
        background-color: #fcfcfc !important;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 15px !important;
        margin: 5px 0 25px 0;
    }
</style>