<template>
    <v-container fluid>

        <!-- <Account /> -->

        <img :src="require('../assets/bank.svg')" v-if="!bank && !loader" alt="">
        <img :src="require('../assets/loading.svg')" v-if="loader" alt="">
        <img :src="require('../assets/bank_success.svg')" v-if="bank" alt="">

        <p v-if="!bank && !loader">We use Stitch - our API partner to connect to your bank account. <br><br> This will
            allow you to see your account balances and transaction history in Moya. You can then easily track your money
            and get an overview of your spending in real time.</p>

        <p v-if="loader">Hang tight while we set things up</p>

        <p v-if="bank">Congratulations, your bank is all setup</p>

        <v-btn class="button" v-if="!bank && learn" :loading="loader" block @click="BuildURL(), loader = true">Connect
            Bank
            Account</v-btn>

        <v-btn class="button" v-if="!bank && !learn && !error && !loader" block @click="dialog= true">Next</v-btn>
        <!-- <p class="skip" v-if="!bank" @click="SkipBank()">Skip for now</p> -->
        <p class="skip" v-if="!bank && learn" @click="dialog= true">Learn more</p>
        <v-btn class="button" v-if="bank" block @click="Continue()">Continue</v-btn>

        <v-btn class="button" v-if="error" block @click="Reload()">Retry</v-btn>




        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">

            <v-card flat class="terms-card">

                <v-app-bar flat color="#fff" fixed>

                    <v-icon class="back" @click="dialog = false">chevron_left</v-icon>

                    <v-toolbar-title class="title">Linking Bank Account</v-toolbar-title>

                    <v-spacer></v-spacer>



                </v-app-bar>

                <v-card-title>
                    Moya needs your permission to access your account data
                </v-card-title>

                <!-- <v-divider class="mx-4"></v-divider> -->

                <img src="../assets/bank_infographic.png" alt="">

                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mx-2">mdi-information</v-icon> How does this work?
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            When you click “allow”, we will securely connect you to our API partner Stitch Money. This
                            grants Stitch the authority to act on your behalf to access and transmit your End User
                            Information from the relevant bank or other entity that provides your bank accounts
                            (collectively known as “Bank Providers”). All information comes in the form of permissioned
                            access granted by you, simply, you need to explicitly grant access to share this
                            information.
                            <br>
                            <br>
                            Neither Stitch, nor Moya is able to access information that you have not explicitly shared.
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mx-2">mdi-information</v-icon>
                                What data am I sharing?
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Moya will have access to the following from your Bank Provider:
                            <br>
                            <br>
                            <ul>
                                <li>Account information</li>
                                <li>Account Holder information</li>
                                <li>Balance</li>
                                <li>Transactions</li>
                            </ul>
                            <br>
                            <br>
                            Neither Stitch, nor Moya is able to access information that you have not explicitly shared.
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mx-2">mdi-information</v-icon>
                                How is my data used?
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Moya will use this data to provide its services. You will be able to categorise your income
                            and
                            expenses and see your money dashboard.
                            <br>
                            <br>
                            You can ask Moya to stop accessing your data at any time.

                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mx-2">mdi-information</v-icon>
                                What banks are supported?
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            We can link to all the major bank accounts in
                            South Africa such as FNB, Nedbank, Standard Bank, Old Mutual, Absa, or Capitec

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-btn class="button mt-10" block @click="dialog = false; learn = true">Allow</v-btn>

                <v-card-subtitle>By continuing you agree to our <a target="_blank"
                        href="https://www.moya.africa/privacy-policy">
                        Terms and Conditions.
                    </a> You can learn more below:</v-card-subtitle>

            </v-card>


        </v-dialog>
















    </v-container>
</template>

<script>
    // import Account from '@/components/Account.vue'

    import {
        Auth
    } from "aws-amplify";
    import {
        API
    } from "aws-amplify";
    import CryptoJS from "crypto-js";

    export default {

        components: {
            // Account
        },

        data: () => ({
            redirectUri: "",
            scopes: [
                "offline_access",
                "openid",
                "accounts",
                "transactions",
                "balances",
                "accountholders",
                "client_imageupload"
            ],
            clientId: null,
            bank: false,
            learn: false,
            dialog: false,
            loader: false,
            error: false
        }),

        methods: {

            Continue() {
                this.$router.push({
                    name: 'Overview'
                }).catch(() => {})
            },

            Reload() {
                location.reload();
            },

            async BuildURL() {

                if (process.env.NODE_ENV == "development") {
                    this.redirectUri = 'https://localhost:8080/stitch';
                    this.clientId = "test-8c7a8fd2-11b0-4bd3-8c9a-0479dcc9bdeb";
                } else {
                    this.redirectUri = 'https://app.moya.africa/stitch';
                    this.clientId = "ac9b6c6c-39f3-44cd-a8b7-923420f59dd4";
                }

                const state = this.generateRandomStateOrNonce();
                const nonce = this.generateRandomStateOrNonce();
                const promise = this.generateVerifierChallengePair();
                Promise.resolve(promise).then((value) => {
                    const challenge = value[1];
                    window.location.replace(
                        this.buildAuthorizationUrl(
                            this.clientId,
                            challenge,
                            this.redirectUri,
                            state,
                            nonce,
                            this.scopes
                        )
                    );
                });
            },

            async generateVerifierChallengePair() {
                const randomBytes = crypto.getRandomValues(new Uint8Array(32));
                const verifier = this.base64UrlEncode(randomBytes);
                localStorage.verifier = verifier;
                const challenge = await this.sha256(verifier).then(this.base64UrlEncode);
                return [verifier, challenge];
            },

            generateRandomStateOrNonce() {
                const randomBytes = crypto.getRandomValues(new Uint8Array(32));
                return this.base64UrlEncode(randomBytes);
            },

            base64UrlEncode(byteArray) {
                const charCodes = String.fromCharCode(...byteArray);
                return window
                    .btoa(charCodes)
                    .replace(/\+/g, "-")
                    .replace(/\//g, "_")
                    .replace(/=/g, "");
            },

            async sha256(verifier) {
                const msgBuffer = new TextEncoder("utf-8").encode(verifier);
                // hash the message
                const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
                return new Uint8Array(hashBuffer);
            },

            buildAuthorizationUrl(
                clientId,
                challenge,
                redirectUri,
                state,
                nonce,
                scopes
            ) {
                const search = {
                    client_id: clientId,
                    code_challenge: challenge,
                    code_challenge_method: "S256",
                    redirect_uri: redirectUri,
                    scope: scopes.join(" "),
                    response_type: "code",
                    nonce: nonce,
                    state: state,
                };
                const searchString = Object.entries(search)
                    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
                    .join("&");
                console.log(`https://secure.stitch.money/connect/authorize?${searchString}`)

                return `https://secure.stitch.money/connect/authorize?${searchString}`;
            },

            async checkURL() {

                const url_string = window.location.href;
                var url = new URL(url_string);
                // console.log(url);
                if (url.searchParams.get("code")) {

                    console.log('Check Code')

                    this.loader = true;

                    const apiName = "MoyaAPI";
                    const apiPath = "/token";
                    const apiRequest = {
                        queryStringParameters: {
                            type: "authorization_code",
                            code: url.searchParams.get("code"),
                            verifier: localStorage.verifier,
                            env: process.env.NODE_ENV
                        },
                    };
                    try {
                        const response = await API.get(apiName, apiPath, apiRequest);
                        // console.log(response.tokenData);
                        const user = await Auth.currentAuthenticatedUser();
                        const access_token = this.EncryptData(
                            response.tokenData.access_token,
                            user.username
                        );
                        const refresh_token = this.EncryptData(
                            response.tokenData.refresh_token,
                            user.username
                        );
                        await Auth.updateUserAttributes(user, {
                            "custom:access_token": access_token,
                            "custom:refresh_token": refresh_token,
                            "custom:form_bank": "1",
                        });
                        this.bank = true;
                        this.learn = true;
                        this.loader = false
                    } catch (error) {
                        if (error.response.status == 400) {
                            console.log(error.response.data.error);
                            this.error = true
                        } else {
                            console.log(error);
                        }
                    }
                } else {
                    this.bank = false;
                    this.learn = false;
                    this.loader = false
                    console.log("User cancelled the bank account link or page just loaded");
                }
            },

            EncryptData(token, secret) {
                if (token.length) {
                    // hash the name with any algorithm
                    const data = CryptoJS.AES.encrypt(token, secret).toString();
                    return data;
                }
            },

            DecryptData(token, secret) {
                // decrypt the data and convert to string
                const decryptData = CryptoJS.AES.decrypt(token, secret).toString(
                    CryptoJS.enc.Utf8
                );
                return decryptData;
            },

            async SkipBank() {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(user, {
                    "custom:form_bank": "0.5",
                });
                this.Continue();
            },

            Info() {
                this.$router.push({
                    name: 'BankInfo'
                }).catch(() => {})
            }

        },

        created() {
            this.checkURL();
        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 40px 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    h1 {
        text-align: center;
        font-weight: bold;
    }

    p {
        text-align: center;
        margin-top: 10px;
        /* font-weight: 200; */
    }

    img {
        width: 250px;
        margin: 20px;
    }

    .skip {
        text-align: center;
        font-weight: bold;
        font-size: 0.9em;
        text-decoration: underline;
        color: #5271ff;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }

    .v-dialog--fullscreen>.v-card {
        min-height: 100%;
        min-width: 100%;
        margin: 0 !important;
        padding: 50px 20px 20px 20px !important;
        /* margin-top: 30px !important; */
    }
</style>