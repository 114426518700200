<template>
    <v-container fluid>
        <v-icon class="back" v-if="step1" @click="Welcome()">chevron_left</v-icon>
        <v-icon class="back" v-if="step2" @click="Name()">chevron_left</v-icon>
        <v-icon class="back" :disabled="loader" v-if="step3" @click="Contact()">chevron_left</v-icon>

        <div class="step">
            <v-icon>face</v-icon>
            <v-icon :disabled="!step1">noise_control_off</v-icon>
            <v-icon :disabled="!step2">noise_control_off</v-icon>
            <v-icon :disabled="!step3">noise_control_off</v-icon>
        </div>

        <v-form ref="form1" v-model="valid1" v-if="step1">
            <h1>What's your name?</h1>
            <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>

            <v-text-field v-model="surname" :rules="nameRules" label="Surname" required></v-text-field>

            <v-btn block :disabled="!valid1" @click="Contact()">
                Next
            </v-btn>
        </v-form>

        <v-form ref="form2" v-model="valid2" v-if="step2">
            <h1>How do we contact you?</h1>
            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

            <v-text-field v-model="phoneNumber" :rules="phoneNumberRules" label="Phone Number" required>
            </v-text-field>

            <v-btn block :disabled="!valid2" :loading="loader" @click="Birth()">
                Next
            </v-btn>
        </v-form>

        <v-form ref="form3" v-model="valid3" v-if="step3">
            <h1>When were you born?</h1>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" label="Birthdate" prepend-icon="mdi-calendar" hint="Please verify that you are 18 years or older!" persistent-hint readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" :active-picker.sync="activePicker"
                    :max="maxDate()"
                    min="1950-01-01" @change="save"></v-date-picker>
            </v-menu>

            <v-btn block :disabled="!date" :loading="loader" @click="Profile(), loader = true">
                Submit
            </v-btn>
        </v-form>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";
    import {
        API
    } from "aws-amplify";

    export default {
        data: () => ({
            valid1: true,
            valid2: true,
            valid3: true,

            loader: false,

            step1: true,
            step2: false,
            step3: false,

            name: '',
            surname: '',
            nameRules: [
                v => !!v || 'Name is required'
            ],

            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            phoneNumber: '',
            phoneNumberRules: [
                v => !!v || 'Phone number is required',
                v =>
                /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/
                .test(v) || 'Invalid phone number'
                // v => (v && v.length <= 20) || 'Name must be less than 10 characters',
            ],

            activePicker: null,
            date: null,
            menu: false,
        }),

        methods: {

            // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,

            Welcome() {
                this.$router.push({
                    name: 'Welcome'
                }).catch(() => {})
            },

            Name() {
                this.step1 = true;
                this.step2 = false;
                this.step3 = false;
                this.loader = false;
            },

            Contact() {
                this.step1 = false;
                this.step2 = true;
                this.step3 = false;
                this.loader = false;
            },

            Birth() {
                this.step1 = false;
                this.step2 = false;
                this.step3 = true;
                this.loader = false;
            },

            maxDate() {
                const date = new Date();
                date.setFullYear(date.getFullYear() - 18);
                return date.toISOString().substr(0, 10);
            },

            async Profile() {
                const apiName = "MoyaAPI";
                const apiPath = "/user";
                const apiRequest = {
                    body: {
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        cell: this.phoneNumber,
                        birthDate: this.date,
                    },
                };
                try {
                    const response = await API.post(apiName, apiPath, apiRequest);
                    console.log(response);

                    const user = await Auth.currentAuthenticatedUser();

                    await Auth.updateUserAttributes(user, {
                        'birthdate': this.date.toString(),
                        'custom:form_profile': '1',
                        'custom:login': '0'
                    })

                    this.$router.push({
                        name: 'BusinessWelcome'
                    })
                    this.loader = false;

                } catch (error) {
                    console.log(error.response);
                    this.loader = false;
                }
            },


            async CurrentUser() {

                Auth.currentAuthenticatedUser({
                        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                    })
                    .then((user) => {
                        // console.log(user.attributes);
                        this.name = user.attributes.given_name;
                        this.surname = user.attributes.family_name;
                        this.email = user.attributes.email;
                    })
                    .catch((err) => console.log(err));
            },

            save(date) {
                this.$refs.menu.save(date)
            },

        },

        created() {
            this.CurrentUser()

            // this.date = this.maxDate();
        },

        watch: {
            menu(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
        }
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 20px 10px !important;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 3em !important;
        text-align: left !important;
    }

    .step {
        margin: 50px 0 10px 15px;
    }

    .step .v-icon {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 1em !important;
        text-align: left !important;
        margin-right: 5px;
    }

    .step .v-icon:first-of-type {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
    }

    .v-form {
        margin: 20px;
    }

    h1 {
        margin-bottom: 30px;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .date {
        margin-bottom: 20px;
    }
</style>