<template>
    <v-container fluid>

        <Account />

        <img :src="require('../assets/bank_success.svg')" alt="">

        <p>Congratulations, your bank is all setup</p>

        <v-btn class="button" block @click="Continue()">Next</v-btn>

    </v-container>
</template>

<script>
    import Account from '@/components/Account.vue'


    export default {

        components: {
            Account
        },

        data: () => ({

        }),

        methods: {

            Continue() {
                this.$router.push({
                    name: 'Successful'
                }).catch(() => {})
            },

            async checkURL() {
                const url_string = window.location.href;

                var url = new URL(url_string);
                if (url.searchParams.get("status") && url.searchParams.get("status") == "complete") {

                    this.$router.push({
                        name: 'Overview'
                    }).catch(() => {})

                } else if (url.searchParams.get("status") && url.searchParams.get("status") == "failed") {

                    this.$router.push({
                        name: 'Overview'
                    }).catch(() => {})
                }

            },




        },

        created() {
            this.checkURL()
        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 40px 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    h1 {
        text-align: center;
        font-weight: bold;
    }

    p {
        text-align: center;
        margin-top: 10px;
        /* font-weight: 200; */
    }

    img {
        width: 250px;
        margin: 30px;
    }
</style>