<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-toolbar fixed flat>
                <!-- <v-icon class="back" @click="Back()">chevron_left</v-icon> -->
                <!-- <v-toolbar-title>Your Personal Profile</v-toolbar-title> -->
                <v-spacer></v-spacer>
                <v-btn icon @click="Notifications()">
                    <v-badge color="#DD1155" dot :value="notification">
                        <v-icon class="account">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </v-toolbar>

        </v-app-bar>

        <template>

            <v-list>
                <v-list class="d-flex flex-column justify-center align-center mx-auto text-center">
                    <v-list-item>
                        <v-avatar color="#5271ff" size="100">
                            <span class="white--text text-h5">{{ initials }}</span>
                        </v-avatar>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{fullName}}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{email}}</v-list-item-subtitle>

                        </v-list-item-content>

                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list nav dense>
                    <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item @click="OpenRoute('Personal')">
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Personal Profile</v-list-item-title>
                                <v-list-item-subtitle>Your personal details</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('Plan')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Your Plan</v-list-item-title>
                                <v-list-item-subtitle>Manage your plan</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenLink('https://airtable.com/shrxvIssArHm8w4NV')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-voice</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Accountant</v-list-item-title>
                                <v-list-item-subtitle>Speak to an accountant</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('Settings')">
                            <v-list-item-icon>
                                <v-icon>mdi-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Settings</v-list-item-title>
                                <v-list-item-subtitle>Notifications and account security</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('Support')">
                            <v-list-item-icon>
                                <v-icon>mdi-face-agent</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Support</v-list-item-title>
                                <v-list-item-subtitle>FAQs, feedback or feature request</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('Learn')">
                            <v-list-item-icon>
                                <v-icon>mdi-school</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Learn</v-list-item-title>
                                <v-list-item-subtitle>Powered by Findamentals</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('BankDetails')">
                            <v-list-item-icon>
                                <v-icon>mdi-credit-card-fast</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Bank Details</v-list-item-title>
                                <v-list-item-subtitle>Your bank details</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('Business')" disabled>
                            <v-list-item-icon>
                                <v-icon disabled>mdi-briefcase</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Business Profile</v-list-item-title>
                                <!-- <v-list-item-subtitle>Your business details</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Coming Soon</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon disabled color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('BankWelcome')" disabled>
                            <v-list-item-icon>
                                <v-icon disabled>mdi-credit-card-refresh-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Add Another Bank Account</v-list-item-title>
                                <!-- <v-list-item-subtitle>Link your bank account</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Coming Soon</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon disabled color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item disabled>
                            <v-list-item-icon>
                                <v-icon disabled>mdi-receipt</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Manage Invoices</v-list-item-title>
                                <!-- <v-list-item-subtitle>Link your bank account</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Coming Soon</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon disabled color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item disabled>
                            <v-list-item-icon>
                                <v-icon disabled>mdi-account-multiple-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Invite Your Friends</v-list-item-title>
                                <v-list-item-subtitle>Help your friends grow too</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item>
                            <v-btn @click="SignOut(), loader = true" :loading="loader" :disabled="loader" class="button"
                                depressed rounded text>
                                Sign out
                            </v-btn>

                        </v-list-item>


                    </v-list-item-group>
                </v-list>

            </v-list>

        </template>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";

    export default {

        components: {

        },

        data: () => ({
            notification: null,
            selectedItem: null,
            initials: '',
            fullName: '',
            email: '',
            loader: false,
        }),

        methods: {
            Notifications() {
                this.$store.dispatch('Notification', false);

                this.$router.push({
                    name: 'Notifications'
                }).catch(() => {})
            },

            async CurrentUser() {

                await Auth.currentAuthenticatedUser({
                        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                    })
                    .then((user) => {
                        // console.log(user.attributes.name);
                        this.fullName = user.attributes.name;
                        this.email = user.attributes.email;
                        this.initials = user.attributes.name.charAt(0);
                    })
                    .catch((err) => console.log(err));
            },

            async SignOut() {

                try {
                    await Auth.signOut({
                        global: true
                    });

                    this.loader = false,

                        this.$router.push({
                            name: 'Splash'
                        }).catch(() => {})

                } catch (error) {

                    if (error.code == "NotAuthorizedException") {

                        this.$router.push({
                            name: 'Splash'
                        }).catch(() => {})

                        this.loader = false

                    }

                    this.loader = false
                }
            },

            Personal() {
                this.$router.push({
                    name: 'Personal'
                }).catch(() => {})
            },

            OpenRoute(link) {
                this.$router.push({
                    name: link
                }).catch(() => {})
            },

            OpenLink(link) {
                window.open(link);
            },
        },

        created() {

            document.title = 'Moya | Profile'

            this.notification = this.Notification;

            this.CurrentUser()
        },

        computed: {
            Notification() {
                return this.$store.getters.Notification
            },
        }
    }
</script>

<style scoped>
    .container {
        padding: 50px 20px !important;
    }

    .button {
        width: 100%;
        align-items: center;
        border-radius: 20px;
        margin: 20px 0 30px 0;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        box-shadow: none !important;
        border: none !important;
    }
</style>