<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>


            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>
            <v-toolbar-title class="title">Settings</v-toolbar-title>
            <v-spacer></v-spacer>


        </v-app-bar>

        <template>

            <v-list>


                <v-list nav dense>

                    <v-list-item-group v-model="selectedItem" color="primary">

                        <v-subheader>About</v-subheader>

                        <v-list-item @click="OpenLink('https://www.moya.africa/privacy-policy')">
                            <v-list-item-icon>
                                <v-icon>mdi-draw</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Terms and Conditions</v-list-item-title>
                                <v-list-item-subtitle>View our terms and conditions</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenLink('https://www.moya.africa/privacy-policy')">
                            <v-list-item-icon>
                                <v-icon>mdi-key</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Privacy Policy</v-list-item-title>
                                <v-list-item-subtitle>View our privacy policy</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-xml</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Version</v-list-item-title>
                                <v-list-item-subtitle>1.0.001</v-list-item-subtitle>
                            </v-list-item-content>

                            <!-- <v-list-item-icon>
                                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                                </v-list-item-icon> -->
                        </v-list-item>

                        <v-subheader>Communication Preferences</v-subheader>

                        <v-list-item disabled>
                            <v-list-item-icon>
                                <v-icon disabled>mdi-email-newsletter</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Newsletter</v-list-item-title>
                                <v-list-item-subtitle>Signup for our Newsletter</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-subheader>Security</v-subheader>

                        <v-list-item @click="OpenLink('https://airtable.com/embed/shr7M6jxi7U4Jc6nJ?backgroundColor=purple')">
                            <v-list-item-icon>
                                <v-icon>mdi-trash-can</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Delete my Account</v-list-item-title>
                                <!-- <v-list-item-subtitle>Change your 4 digit code</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Request for your account to be deleted</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="#5271ff">mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item @click="OpenRoute('PIN')" disabled>
                            <v-list-item-icon>
                                <v-icon disabled>mdi-form-textbox-password</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Moya PIN</v-list-item-title>
                                <!-- <v-list-item-subtitle>Change your 4 digit code</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Coming Soon</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>

            </v-list>

        </template>

    </v-container>
</template>

<script>
    export default {

        components: {

        },

        data: () => ({
            selectedItem: null,
            loader: false,
        }),

        methods: {

            OpenRoute(link) {
                this.$router.push({
                    name: link
                }).catch(() => {})
            },

            OpenLink(link) {
                window.open(link);
            },
        },

        created() {
            document.title = 'Moya | Settings'
        },
    }
</script>

<style scoped>
    .container {
        padding: 50px 20px !important;
    }

    .button {
        width: 100%;
        align-items: center;
        border-radius: 20px;
        margin: 20px 0 30px 0;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        box-shadow: none !important;
        border: none !important;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    .v-list--dense .v-subheader {
        font-size: 0.8em;
        font-weight: bold;
        text-transform: uppercase;
    }
</style>