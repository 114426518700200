<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>

            <v-toolbar-title class="title">Linking Bank Account</v-toolbar-title>

            <v-spacer></v-spacer>



        </v-app-bar>

        <v-card flat>
            <v-card-title>
                Moya needs your permission to access your account data
            </v-card-title>

            <v-card-subtitle class="my-2">By continuing you agree to our <a target="_blank"
                    href="https://www.moya.africa/privacy-policy">
                    Terms and Conditions.
                </a> You can learn more below</v-card-subtitle>


            <!-- <v-divider class="mx-4"></v-divider> -->

            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <v-icon class="mx-2">mdi-information</v-icon> How does this work?
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        When you click “allow”, we will securely connect you to our API partner Stitch Money. This
                        grants Stitch the authority to act on your behalf to access and transmit your End User
                        Information from the relevant bank or other entity that provides your bank accounts
                        (collectively known as “Bank Providers”). All information comes in the form of permissioned
                        access granted by you, simply, you need to explicitly grant access to share this information.
                        <br>
                        <br>
                        Neither Stitch, nor Moya is able to access information that you have not explicitly shared.
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <v-icon class="mx-2">mdi-information</v-icon>
                            What data am I sharing?
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Moya will have access to the following from your Bank Provider:
                        <br>
                        <br>
                        <ul>
                            <li>Account information</li>
                            <li>Account Holder information</li>
                            <li>Balance</li>
                            <li>Transactions</li>
                        </ul>
                        <br>
                        <br>
                        Neither Stitch, nor Moya is able to access information that you have not explicitly shared.
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                            <v-icon class="mx-2">mdi-information</v-icon>
                            How is my data used?
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Moya will use this data to provide its services. You will be able to categorise your income and
                        expenses and see your money dashboard.
                        <br>
                        <br>
                        You can ask Moya to stop accessing your data at any time.

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <v-btn class="button" block @click="$router.go(-1)">Allow</v-btn>

    </v-container>
</template>

<script>
    export default {
        data: () => ({
            tab: null,
            loader: false,
        }),

        methods: {

            OpenLink(link) {
                window.open(link);
            },
        }

    }
</script>

<style scoped>
    .container {
        padding: 60px 30px 60px !important;
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    h4 {
        margin-bottom: 10px;
    }

    h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 20px;
        width: 100%;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }
</style>