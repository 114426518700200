<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>

            <v-toolbar-title class="title">Learn</v-toolbar-title>

            <v-spacer></v-spacer>



        </v-app-bar>

        <v-card flat>
            <v-card-title>
                Finance Crash Courses
            </v-card-title>

            <v-card-subtitle class="my-2">We are offering a ONE Month financial course for freelancers so you can learn the basics and be in control of your money.</v-card-subtitle>


            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <img src="../../assets/Moya Findamental Collab.png" alt="">

                <v-btn class="button" block @click="OpenLink('https://airtable.com/embed/shrZpMdv5X9GI7OpQ?backgroundColor=purple')">Interested</v-btn>
            </v-card-text>

            
        </v-card>

    </v-container>
</template>

<script>
    export default {
        data: () => ({
            tab: null,
            loader: false,
        }),

        methods: {

            OpenLink(link) {
                window.open(link);
            },
        },

        created() {
            document.title = 'Moya | Learn'
        },

    }
</script>

<style scoped>
    .container {
        padding: 60px 30px 60px !important;
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    img {
        width: 100%;
    }

    h4 {
        margin-bottom: 10px;
    }

    h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 25px;
        width: 100%;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }
</style>