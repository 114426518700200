<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>

            <v-toolbar-title class="title">PIN</v-toolbar-title>

            <v-spacer></v-spacer>



        </v-app-bar>

        <v-card flat>
            <v-card-title>
                Login PIN
            </v-card-title>

            <v-card-subtitle class="my-2">Secure your account...</v-card-subtitle>


            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <div>
                    <div class="ma-auto position-relative" style="max-width: 300px">
                        <v-otp-input v-model="otp" :disabled="loading" @finish="onFinish" length="4"></v-otp-input>
                    </div>
                    <!-- <div>
                        Expected value: <span class="font-weight-bold">{{ expectedOtp }}</span>
                    </div> -->

                    <v-btn class="button" block :loading="loading" @click="UpdatePIN(), loader = true">
                        Update
                    </v-btn>

                    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000">
                        {{ text }}
                    </v-snackbar>
                </div>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";

    export default {
        data: () => ({
            tab: null,
            loader: false,

            loading: false,
            snackbar: false,
            snackbarColor: 'default',
            otp: '',
            text: '',
            expectedOtp: '1234',
        }),

        methods: {

            OpenLink(link) {
                window.open(link);
            },

            onFinish(rsp) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    this.snackbarColor = (rsp === this.expectedOtp) ? 'success' : 'warning'
                    this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`
                    this.snackbar = true
                }, 3500)
            },

            async UpdatePIN() {
                this.loading = true
                try {
                    const user = await Auth.currentAuthenticatedUser();
                    

                    let response = await Auth.updateUserAttributes(user, {
                        'custom:pin': '1234',
                    })

                    console.log(response)

                    this.loading = false
                    
                } catch (error) {
                    console.log(error)

                    this.loading = false
                }
            }
        }

    }
</script>

<style scoped>
    .container {
        padding: 60px 30px 60px !important;
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    h4 {
        margin-bottom: 10px;
    }

    h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
        width: 100%;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }
</style>