<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>

            <v-toolbar-title class="title">Business Profile</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="PatchBusiness(), loader=true" :loading="loader">
                <v-icon class="account">mdi-update</v-icon>
            </v-btn>

            <template v-slot:extension>
                <v-tabs v-model="tab" fixed-tabs>
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab>
                        <v-icon>mdi-domain</v-icon>
                    </v-tab>
                    <v-tab>
                        <v-icon>mdi-phone</v-icon>
                    </v-tab>
                    <v-tab>
                        <v-icon>mdi-file-document</v-icon>
                    </v-tab>
                    <v-tab>
                        <v-icon>mdi-link-variant</v-icon>
                    </v-tab>
                </v-tabs>
            </template>

        </v-app-bar>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card flat>
                    <h2>Business</h2>

                    <v-form ref="form" v-model="valid" lazy-validation>

                        <h5>What's the name of your business?</h5>

                        <v-text-field v-model="businessName" :rules="nameRules" label="Business Name" required>
                        </v-text-field>

                        <h5>What's your role in the business?</h5>

                        <v-text-field v-model="role" :rules="nameRules" label="Business Role" required>
                        </v-text-field>

                        <h5>How much time do you spend on your business?</h5>

                        <v-radio-group v-model="time" mandatory hide-details>
                            <v-radio label="Main Hustle (Full Time)" value="main_hustle"></v-radio>
                            <v-radio label="Side Hustle (Part Time)" value="side_hustle"></v-radio>
                        </v-radio-group>

                        <h5>What is your legal setup?</h5>

                        <v-radio-group v-model="company" mandatory hide-details>
                            <v-radio label="Sole proprietor (Self Employed)" value="self"></v-radio>
                            <!-- <v-radio label="Sole Proprietor" value="sole"></v-radio> -->
                            <v-radio label="Private Limited Company (Registered with CIPC)" value="limited"></v-radio>
                        </v-radio-group>

                        <v-text-field v-if="company == 'limited' || company == 'sole'" v-model="registration"
                            label="Company Registration Number" hint="You can fill this in later" persistent-hint>
                        </v-text-field>

                        <h5>Are you tax registered?</h5>

                        <v-radio-group v-model="tax" mandatory hide-details>
                            <v-radio label="No" value="no"></v-radio>
                            <v-radio label="Yes" value="yes"></v-radio>
                        </v-radio-group>

                        <v-text-field v-if="tax == 'yes'" v-model="taxNumber" label="Tax Number"
                            hint="You can fill this in later" persistent-hint>
                        </v-text-field>

                        <h5>What year did you start your business?</h5>

                        <v-text-field v-model="yearStarted" :rules="nameRules" label="Year Started" required>
                        </v-text-field>

                        <h5>Upload your business logo?</h5>

                        <div v-if="logoBase" style="width:100%; padding: 1px 50px ">
                            <img :src="logoBase" class="mx-auto" style="width:100%" />
                        </div>
                        <v-file-input accept="image/png, image/jpeg, image/bmp" placeholder="Upload Logo"
                            prepend-icon="mdi-camera" persistent-hint hint=" Recommended Size: (512px X 512px)"
                            label="Logo" @change="encodeLogoAsURL">
                        </v-file-input>

                        <v-btn class="button" block :loading="loader" @click="PatchBusiness(), loader = true">
                            Update
                        </v-btn>

                    </v-form>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <h2>Contact</h2>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <h5>What is your work email?</h5>
                        <v-text-field v-model="email" :rules="emailRules" label="E-mail" prepend-icon="mdi-email"
                            required>
                        </v-text-field>
                        <h5>What is your work number?</h5>
                        <v-text-field v-model="phoneNumber" :rules="phoneNumberRules" label="Phone Number"
                            prepend-icon="mdi-phone" required>
                        </v-text-field>
                        <h5>What is your work address?</h5>
                        <v-text-field v-model="address" :rules="nameRules" label="Address" prepend-icon="mdi-map-marker"
                            required>
                        </v-text-field>

                        <v-btn class="button" block :loading="loader" @click="PatchBusiness(), loader = true">
                            Update
                        </v-btn>
                    </v-form>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <h2>Services</h2>

                    <v-form ref="form" v-model="valid" lazy-validation>

                        <h5>What industry are you in?</h5>

                        <v-select v-model="industry" :items="industryItems" label="Industry"></v-select>

                        <h5>What specific services do you offer?</h5>

                        <v-combobox v-model="service" :items="serviceItems" chips clearable label="Services" multiple
                            hint="Press enter after each service" persistent-hint :rules="nameRules">
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                    @click:close="RemoveService(item)">
                                    <strong>{{ item }}</strong>
                                </v-chip>
                            </template>
                        </v-combobox>

                        <h5>How do you provide your service?</h5>

                        <v-radio-group v-model="serviceProvided" mandatory hide-details>
                            <v-radio label="On Site" value="on-site"></v-radio>
                            <v-radio label="Remote" value="remote"></v-radio>
                            <v-radio label="On Site & Remote" value="both"></v-radio>
                        </v-radio-group>

                        <h5>Revenue stream?</h5>

                        <v-combobox v-model="revenue" :items="revenueItems" chips clearable label="Revenue Stream"
                            multiple hint="Press enter after each revenue" persistent-hint :rules="nameRules">
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                    @click:close="RemoveRevenue(item)">
                                    <strong>{{ item }}</strong>
                                </v-chip>
                            </template>
                        </v-combobox>

                        <h5>What is your business one liner?</h5>

                        <v-text-field v-model="proposition" :rules="nameRules" label="Proposition" required>
                        </v-text-field>

                        <h5>Why did you start this business?</h5>

                        <v-text-field v-model="purpose" :rules="nameRules" label="Purpose" required>
                        </v-text-field>

                        <v-btn class="button" block :loading="loader" @click="PatchBusiness(), loader = true">
                            Update
                        </v-btn>

                    </v-form>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <h2>Social Media</h2>

                    <div v-for="(social,k) in socials" :key="k">
                        <h5>Link {{k + 1}}</h5>
                        <v-text-field v-model="social.S" label="Social Media"
                            :append-icon="k || ( !k && socials.length > 1) ? 'mdi-minus' : undefined"
                            :append-outer-icon="k == socials.length-1 ? 'mdi-plus' : undefined"
                            @click:append="RemoveSocial(k)" @click:append-outer="AddSocial(k)" required>
                        </v-text-field>

                    </div>

                    <v-btn class="button" block :loading="loader" @click="PatchBusiness(), loader = true">
                        Update
                    </v-btn>

                </v-card>
            </v-tab-item>
        </v-tabs-items>

    </v-container>
</template>

<script>
    // import {
    //     Auth
    // } from "aws-amplify";
    import {
        API
    } from "aws-amplify";

    export default {
        data: () => ({
            tab: null,
            loader: false,
            valid: true,

            businessID: '',
            businessName: '',
            nameRules: [
                v => !!v || 'Name is required',
                // v => (v && v.length <= 20) || 'Name must be less than 20 characters',
            ],

            role: '',

            logoBase: '',
            currentLogoBase: '',
            defaultLogo: '',

            industry: '',
            industryItems: ['Automobile', 'Creative', 'Healthcare', 'Food & Catering', 'Writing & Editing',
                'Marketing', 'Customer Service', 'Professional Services', 'Administration', 'Consulting',
                'Management', 'Education'
            ],

            service: [],
            serviceItems: [],

            serviceProvided: '',

            revenue: [],
            revenueItems: [],

            proposition: '',

            purpose: '',

            time: null,

            company: null,
            registration: '',

            tax: null,
            taxNumber: '',

            yearStarted: '',

            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            phoneNumber: '',
            phoneNumberRules: [
                v => !!v || 'Phone number is required'
                // v => (v && v.length <= 20) || 'Name must be less than 10 characters',
            ],

            address: '',

            socials: [{
                S: ''
            }]
        }),

        methods: {
            save(date) {
                this.$refs.menu.save(date)
            },

            Back() {
                this.$router.push({
                    name: 'Settings'
                }).catch(() => {})
            },

            RemoveService(item) {
                this.service.splice(this.service.indexOf(item), 1)
                this.service = [...this.service]
            },

            RemoveRevenue(item) {
                this.revenue.splice(this.revenue.indexOf(item), 1)
                this.revenue = [...this.revenue]
            },

            encodeLogoAsURL(e) {

                if (e) {

                    const reader = new FileReader();

                    reader.onload = (e) => {

                        this.logoBase = e.target.result;
                        this.currentLogoBase = e.target.result;
                        // console.log(this.logoBase);
                    };

                    reader.readAsDataURL(e);
                } else {

                    this.logoBase = '';
                    this.currentLogoBase = this.defaultLogo;

                }
            },

            AddSocial() {
                this.socials.push({
                    S: ''
                });
            },
            RemoveSocial(index) {
                this.socials.splice(index, 1);
            },

            async GetUser() {
                const apiName = "MoyaAPI";
                const apiPath = "/user";
                try {
                    const response = await API.get(apiName, apiPath);
                    this.businessID = response.response.Item.Business_Ids.SS[0];
                    this.email = response.response.Item.Email.S;
                    this.phoneNumber = response.response.Item.Cell.N;

                    this.GetBusiness(response.response.Item.Business_Ids.SS[0])
                } catch (error) {
                    console.log(error.response);
                }
            },


            async GetBusiness(id) {
                console.log(id)
                const apiName = "MoyaAPI";
                const apiPath = "/business";
                const apiRequest = {
                    queryStringParameters: {
                        business_id: id,
                    },
                };
                try {
                    const response = await API.get(apiName, apiPath, apiRequest);
                    console.log(response);

                    if (!response.response.Item.Role) {
                        this.businessName = response.response.Item.Business_Name.S;
                        this.time = response.response.Item.Availability.S;
                        this.industry = response.response.Item.Industry.S;
                        this.company = response.response.Item.Legal_Setup.S;
                        this.registration = response.response.Item.Registration_Number.S;
                        this.service = response.response.Item.Services.SS;
                    } else {
                        this.businessName = response.response.Item.Business_Name.S;
                        this.time = response.response.Item.Availability.S;
                        this.industry = response.response.Item.Industry.S;
                        this.company = response.response.Item.Legal_Setup.S;
                        this.registration = response.response.Item.Registration_Number.S;
                        this.service = response.response.Item.Services.SS;
                        this.role = response.response.Item.Role.S;

                        this.proposition= response.response.Item.Proposition.S,
                        this.purpose= response.response.Item.Purpose.S,
                        this.email= response.response.Item.Work_Email.S,
                        this.phoneNumber= response.response.Item.Whatsapp.N,
                        this.yearStarted = response.response.Item.Year_Started.N,
                        this.address= response.response.Item.Location.S,
                        this.taxNumber= response.response.Item.Tax_Number.S,
                        this.socials= response.response.Item.Social_Media.L,
                        this.serviceProvided= response.response.Item.Service_Provided.S,
                        this.revenue= response.response.Item.Revenue_Streams.SS

                        if(this.taxNumber != '') {
                            this.tax  = 'yes'
                        }

                    }

                } catch (error) {
                    console.log(error);
                }
            },

            async PatchBusiness() {
                const apiName = "MoyaAPI";
                const apiPath = "/business";
                const apiRequest = {
                    body: {
                        business_id: this.businessID,
                        industry: this.industry,
                        services: this.service,
                        availability: this.time,
                        legalSetup: this.company,
                        regNumber: this.registration,
                        proposition: this.proposition,
                        role: this.role,
                        purpose: this.purpose,
                        workEmail: this.email,
                        whatsapp: this.phoneNumber,
                        yearStarted: this.yearStarted,
                        location: this.address,
                        taxNumber: this.taxNumber,
                        socialMedia: this.socials,
                        serviceProvided: this.serviceProvided,
                        revenueStreams: this.revenue,
                        businessLogo: this.logoBase
                    },
                };
                try {
                    const response = await API.patch(apiName, apiPath, apiRequest);
                    console.log(response);
                    this.loader = false;

                } catch (error) {
                    console.log(error);
                    this.loader = false;
                }
            },

        },

        created() {
            this.GetUser();
        }
    }
</script>

<style scoped>
    .container {
        padding: 120px 30px 60px !important;
    }

    h4 {
        margin-bottom: 10px;
    }

    h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

</style>