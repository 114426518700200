<template>
    <v-container fluid>
        <v-icon @click="$router.go(-1)">chevron_left</v-icon>

        <v-form ref="form" v-model="valid">
            <h1>Create account</h1>
            <v-text-field v-model="name" :rules="nameRules" label="Name" autocomplete="given-name" required></v-text-field>

            <v-text-field v-model="surname" :rules="nameRules" label="Surname" autocomplete="family-name" required></v-text-field>

            <v-text-field v-model="email" :rules="emailRules" label="E-mail" autocomplete="username" required></v-text-field>

            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, rules.pattern]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                label="Password" autocomplete="new-password" hint="At least 8 characters" counter @click:append="show1 = !show1">
            </v-text-field>

            <v-text-field v-model="password2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(password === password2) || 'Password must match']" :type="show2 ? 'text' : 'password'"
                name="input-10-1" label="Password(Confirm)" autocomplete="new-password" hint="At least 8 characters" counter
                @click:append="show2 = !show2">
            </v-text-field>

            <v-checkbox v-model="terms" :rules="[v => !!v || 'You must agree to continue!']" required>
                <template v-slot:label>
                    <div>
                        By creating an account, you agree to our
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a target="_blank" href="https://www.moya.africa/privacy-policy" @click.stop v-on="on">
                                    Terms and Conditions and Privacy Policy
                                </a>
                            </template>
                            Opens in new window
                        </v-tooltip>
                    </div>
                </template>
            </v-checkbox>

            <!-- <v-checkbox class="my-0" v-model="privacy" :rules="[v => !!v || 'You must agree to continue!']" required>
                <template v-slot:label>
                    <div>
                        By creating an account, you agree to our
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a target="_blank" href="https://moya.africa" @click.stop v-on="on">
                                    Privacy Policy
                                </a>
                            </template>
                            Opens in new window
                        </v-tooltip>
                    </div>
                </template>
            </v-checkbox> -->

            <v-btn block :disabled="!valid || loader" :loading="loader" @click="Signup(), loader = true">
                Sign Up
            </v-btn>
            <p class="signin">Already have an account? <span>
                    <router-link to="/signin">Sign in</router-link>
                </span></p>
        </v-form>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";
    export default {
        data: () => ({
            valid: true,
            loader: false,

            name: '',
            surname: '',
            nameRules: [
                v => !!v || 'Name is required'
            ],

            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            terms: false,
            privacy: false,

            show1: false,
            password: '',

            show2: false,
            password2: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
                pattern: v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(v) || 'Password must contain one of each of the following characters: 1 uppercase, 1 lowercase, 1 number & 1 symbol'
            },
        }),

        methods: {

            async Signup() {

                try {
                    const {
                        user
                    } = await Auth.signUp({
                        username: this.email.toLowerCase().replace(/\s/g, ''),
                        password: this.password,
                        attributes: {
                            name: this.name + " " + this.surname,
                            given_name: this.name,
                            family_name: this.surname,
                            picture: "none",
                            birthdate: "01/01/2022",
                            updated_at: Date.now().toString(),
                            'custom:form_business': '0',
                            'custom:form_profile': '0',
                            'custom:form_bank': '0'
                        },
                    });
                    // console.log(user);

                    this.$router.push({
                        name: 'ConfirmEmail'
                    }).catch(() => {})

                    if(user) {
                        return
                    }

                } catch (error) {
                    alert(error.message);
                    this.loader = false
                }
            },

            validate() {
                this.$refs.form.validate()
            },

            Splash() {
                this.$router.push({
                    name: 'Splash'
                }).catch(() => {})
            },
        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 20px 10px !important;
    }

    .v-icon {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 3em !important;
        text-align: left !important;
    }

    .v-form {
        margin: 20px;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
    }

    .signin {
        position: relative;
        bottom: 0;
        /* margin: 0 auto; */
        width: 100%;
        text-align: center;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
        color: #5271ff;
    }
</style>