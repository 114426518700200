<template>
    <v-container fluid>
    
        <img :src="require('../assets/welcome.svg')" alt="">

        <h2>Welcome <span>{{name}}</span></h2>

        <p>Tell us a little bit about yourself</p>

        <v-btn block @click="Continue()">Continue</v-btn>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";

    export default {
        data: () => ({
            name: null
        }),

        methods: {

            Continue() {
                this.$router.push({
                    name: 'ProfileSetup'
                }).catch(() => {})
            },

            async CurrentUser() {
                
                Auth.currentAuthenticatedUser({
                        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                    })
                    .then((user) => {
                        // console.log(user.attributes.name);
                        this.name = user.attributes.given_name;
                    })
                    .catch((err) => console.log(err));
            },


        },

        created() {
            
            this.CurrentUser()

        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 40px 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    h1 {
        text-align: center;
        font-weight: bold;
    }

    p {
        text-align: center;
        margin-top: 10px;
    }

    img {
        width: 250px;
        margin: 30px;
    }


</style>