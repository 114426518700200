<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>


            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>
            <v-toolbar-title class="title">Notifications</v-toolbar-title>
            <v-spacer></v-spacer>



        </v-app-bar>


            <v-list three-line>
                <template v-for="(item, index) in items">

                    <v-divider v-if="item.divider" :key="index" ></v-divider>

                    <v-list-item v-else :key="item.title" link @click="Open(item.link)">
                        <v-list-item-avatar>
                            <v-img src="../../assets/logo.png"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-html="item.title"></v-list-item-title>
                            <v-list-item-subtitle v-html="item.time"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        
    </v-container>
</template>

<script>
    // import {
    //     Auth
    // } from "aws-amplify";
    // import {
    //     API
    // } from "aws-amplify";

    export default {
        data: () => ({
            items: [
                {
                    title: 'Bank data refresh',
                    link: '',
                    time: 'This has been disabled for maintenance'
                },
                // {
                //     divider: true,
                //     inset: true
                // },
                // {
                //     title: 'Complete Personal Profile',
                //     link: 'Personal',
                //     time: '10 minutes ago'
                // },
                // {
                //     divider: true,
                //     inset: true
                // },
                // {
                //     title: 'Complete Business Profile',
                //     link: 'Business',
                //     time: '10 minutes ago'
                // },
                // {
                //     divider: true,
                //     inset: true
                // },
                // {
                //     title: 'Give us feedback',
                //     link: 'Feedback',
                //     time: '10 minutes ago'
                // },
 
            ],

        }),

        methods: {
            Open(link) {
                this.$router.push({
                    name: link
                }).catch(() => {})
            },
        },

        created() {
            document.title = 'Moya | Notifications'
        },
    }
</script>

<style scoped>
    .container {
        padding: 70px 30px 60px !important;
    }

    h4 {
        margin-bottom: 10px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 30px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }
</style>