<template>


    <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
            <v-btn class="account" color="#5271ff" icon x-large v-on="on">
                <v-icon>logout</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                    <v-avatar color="#5271ff">
                        <span class="white--text text-h5">{{ initials }}</span>
                    </v-avatar>
                    <h3 class="mt-3">{{ fullName }}</h3>
                    <p class="text-caption mt-1">
                        {{email }}
                    </p>
                    <v-divider class="my-3"></v-divider>
                    <v-btn @click="SignOut(), loader = true" :loading="loader" :disabled="loader" class="button" depressed rounded text>
                        Sign out
                    </v-btn>
                </div>
            </v-list-item-content>
        </v-card>
    </v-menu>


</template>

<script>
    import {
        Auth
    } from "aws-amplify";

    export default {
        data: () => ({

            initials: '',
            fullName: '',
            email: '',
            loader: false,

        }),

        methods: {

            async CurrentUser() {

                // console.log('run')

                await Auth.currentAuthenticatedUser({
                        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                    })
                    .then((user) => {
                        // console.log(user.attributes.name);
                        this.fullName = user.attributes.name;
                        this.email = user.attributes.email;
                        this.initials = user.attributes.name.charAt(0);
                    })
                    .catch((err) => console.log(err));
            },

            async SignOut() {

                try {
                    await Auth.signOut({
                        global: true
                    });

                    this.loader = false,

                    this.$router.push({
                        name: 'Splash'
                    }).catch(() => {})

                } catch (error) {

                    if (error.code == "NotAuthorizedException") {

                        this.$router.push({
                            name: 'Splash'
                        }).catch(() => {})

                        this.loader = false

                    }

                    this.loader = false
                }
            },


        },

        created() {
            this.CurrentUser()

        },
    }
</script>

<style scoped>
    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        box-shadow: none !important;
        border: none !important;
    }

    h1 {
        text-align: center;
        font-weight: bold;
    }

    p {
        text-align: center;
        margin-top: 10px;
        /* font-weight: 200; */
    }

    img {
        width: 250px;
        margin: 30px;
    }

    .account {
        position: absolute;
        top: 10px;
        right: 10px;
    }
</style>