<template>
    <v-container fluid>

        <h3>Your account has successfully been created.</h3>

        <img :src="require('../assets/checks.svg')" alt="">

        <h4>A welcome email has been sent to you. Please verify your email with the link provided and then sign in.</h4>
        <p>Did not receive email? <span @click="CurrentUser()">Resend</span></p>

        <v-btn block @click="Signin()">Sign in</v-btn>

    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";
    export default {
        data: () => ({

        }),

        methods: {

            Signin() {
                this.$router.push({
                    name: 'Signin'
                }).catch(() => {})
            },

            async CurrentUser() {
                Auth.currentAuthenticatedUser({
                        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                    })
                    .then((user) => {
                        // console.log(user.attributes.email);
                        this.ResendConfirmationCode(user.attributes.email);
                    })
                    .catch((err) => console.log(err));
            },

            async ResendConfirmationCode(email) {
                try {
                    await Auth.resendSignUp(email);
                    console.log('code resent successfully');
                } catch (err) {
                    console.log('error resending code: ', err);
                }
            },
        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 40px 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    h3 {
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    img {
        width: 250px;
        margin: 30px;
    }

    p {
        /* color: #fff; */
        text-align: center;
        margin-top: 10px;
        /* font-size: 0.9em; */
        /* font-weight: 300; */
    }

    p span {
        text-decoration: underline;
        color: #5271ff
    }
</style>