<template>
    <v-container fluid>
        <v-icon @click="Splash()" v-if="step1">chevron_left</v-icon>
        <v-icon @click="Back()" v-if="step2">chevron_left</v-icon>

        <v-form ref="form1" v-model="valid1" v-if="step1">
            <h1>Forgot Password</h1>
            <p>Please enter your email address</p>

            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

            <v-btn block :disabled="!valid1 || loader" :loading="loader" @click="Forgot(), loader = true">
                Send Verification Code
            </v-btn>

        </v-form>

        <v-form ref="form2" v-model="valid2" v-if="step2">

            <h1>Choose A New Password</h1>
            <p>A verification code has been sent to your email address</p>

            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

            <v-text-field v-model="code" label="Verification Code" required></v-text-field>

            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1">
            </v-text-field>

            <v-text-field v-model="password2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(password === password2) || 'Password must match']" :type="show2 ? 'text' : 'password'"
                name="input-10-1" label="Password(Confirm)" hint="At least 8 characters" counter
                @click:append="show2 = !show2">
            </v-text-field>

            <v-btn block :disabled="!valid2 || loader" :loading="loader" @click="Reset(), loader = true">
                Reset Password
            </v-btn>

            <p class="receive" @click="Back()">Did not receive code?</p>

        </v-form>
    </v-container>
</template>

<script>
    import {
        Auth
    } from "aws-amplify";
    // import { API } from "aws-amplify";

    export default {
        data: () => ({
            step1: true,
            step2: false,

            valid1: true,
            valid2: true,
            loader: false,

            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],

            show1: false,
            password: '',

            show2: false,
            password2: '',

            code: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
        }),

        methods: {

            Splash() {
                this.$router.push({
                    name: 'Splash'
                }).catch(() => {})
            },

            Forgot() {

                Auth.forgotPassword(this.email.toLowerCase())
                    .then(data => {
                        console.log(data)
                        this.loader = false
                        this.step1 = false
                        this.step2 = true
                    })
                    .catch(err => {
                        console.log(err)
                        this.loader = false
                    });
            },

            Back() {
                this.step1 = true
                this.step2 = false
            },

            Reset() {
                Auth.forgotPasswordSubmit(this.email.toLowerCase(), this.code, this.password2)
                    .then(data => {
                        console.log(data)
                        this.loader = false
                        this.$router.push({
                            name: 'Signin'
                        }).catch(() => {})
                    })
                    .catch(err => {
                        console.log(err)
                        this.loader = false
                    });
            }

        },
    }
</script>

<style scoped>
    .container {
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 20px 10px !important;
    }

    .v-icon {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 3em !important;
        text-align: left !important;
    }

    .v-form {
        margin: 20px;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        margin-bottom: 10px;
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
    }

    .receive {
        text-align: center;
        font-weight: bold;
        font-size: 0.9em;
        text-decoration: underline;
        color: #5271ff;
    }
</style>