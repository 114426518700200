<template>
    <v-container fluid>

        <v-app-bar flat color="#fff" fixed>

            <v-icon class="back" @click="$router.go(-1)">chevron_left</v-icon>

            <v-toolbar-title class="title">Our Plans</v-toolbar-title>

            <v-spacer></v-spacer>

            <template v-slot:extension>
                <v-tabs v-model="tab" fixed-tabs>
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab>
                        Free
                    </v-tab>
                    <v-tab>
                        Paid
                    </v-tab>
                </v-tabs>
            </template>

        </v-app-bar>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card tile>

                    <v-card-title>Trial Version</v-card-title>
                    <v-card-subtitle>Free - valid for 1 month</v-card-subtitle>

                    <v-card-text>

                        <v-list>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Connect 1 Bank Account</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Up to 3 months transaction history</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Unlimited Categorisation</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Basic Dashboard</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>1 x Accountant session with a group</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-btn class="button" @click="OpenLink('https://airtable.com/shrisMQuq0UkUrkvc')">
                                    Review
                                </v-btn>
                            </v-list-item>


                        </v-list>
                    </v-card-text>

                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card tile>

                    <v-card-title>Start Plan</v-card-title>
                    <v-card-subtitle>Paid Plan - Coming soon</v-card-subtitle>

                    <v-card-text>

                        <v-list>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Connect 1 Bank Account</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Up to 3 months transaction history</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Unlimited Categorisation</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Advanced Dashboard</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>2 x Accountant session with a group</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Invoicing feature</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mx-4"></v-divider>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="#5271ff">
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <h4>Download profile for clients</h4>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-btn class="button" @click="OpenLink('https://airtable.com/shrisMQuq0UkUrkvc')">
                                    Interested?
                                </v-btn>
                            </v-list-item>


                        </v-list>
                    </v-card-text>

                </v-card>
            </v-tab-item>

        </v-tabs-items>

    </v-container>
</template>

<script>
    export default {
        data: () => ({
            tab: null,
            loader: false,
        }),

        methods: {

            OpenLink(link) {
                window.open(link);
            },
        },

        created() {
            document.title = 'Moya | Plan'
        },

    }
</script>

<style scoped>
    .container {
        padding: 100px 30px 60px !important;
    }

    .title {
        font-size: 20px !important;
        margin-left: 20px;
        color: #5271ff;
    }

    h4 {
        margin-bottom: 0;
    }

    h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .button {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff;
        height: 40px !important;
        /* margin-bottom: 30px; */
        box-shadow: none !important;
        border: none !important;
        margin-top: 10px;
        width: 100%;
    }

    .back {
        color: #5271ff !important;
        font-weight: bold;
        font-size: 2em !important;
        text-align: left !important;
        /* margin-bottom: 50px; */
    }
</style>